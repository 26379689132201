<template>
  <div>
    <div
      v-if="isIE11"
      class="d-flex w-100 h-100 justify-content-center align-items-center"
    >
      <h4 class="text-center px-3">
        This browser is not supported.
        Please use a supported browser such as Edge, Firefox, or Chrome.
        <br><br>
        Denne browser er ikke understøttet.
        Brug venligst en understøttet browser fx Edge, Firefox eller Chrome.
      </h4>
    </div>
    <app-template
      v-else
      v-bind="{
        pages,
        showSidebar: loggedIn,
        userDetails,
        userOptionPages,
        toolDetails: {
          name: 'SupChat',
          logo: require('./assets/supwiz-logo-flat.svg'),
          env,
          version: supchatVersion.version,
        },
        loggedIn,
        localizationObj,
        noMainPage: true,
      }"
    >
      <template #header>
        <locale-select />
      </template>
      <template
        v-if="loggedIn"
        #before-nav
      >
        <div
          class="px-3 mt-3 d-flex justify-content-center"
          style="z-index:500"
        >
          <LoginStatus />
        </div>
      </template>
      <template
        v-if="loggedIn"
        #after-nav
      >
        <AgentStatusList />
      </template>
      <template #page-content>
        <transition
          mode="out-in"
          name="fade"
        >
          <router-view />
        </transition>
        <chat-entry
          v-if="loadChatComp"
          ref="chat"
          slot="footer"
        />
        <chat-notification
          v-if="loadChatComp"
          slot="notification"
        />
        <error-display />
        <NotificationPopover />
      </template>
    </app-template>
    <NotificationsGuideModal ref="GuideModal" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { sortBy } from 'lodash';
import AppTemplate from 'supwiz/components/template/AppTemplate.vue';

import LoginStatus from '@/components/Sidebar/LoginStatus.vue';
import AgentStatusList from '@/components/Sidebar/AgentsStatusList.vue';
import ChatEntry from '@/components/chatModal/ChatEntry.vue';
import ChatNotification from '@/components/ChatNotification.vue';
import ErrorDisplay from '@/components/ErrorDisplay.vue';
import LocaleSelect from '@/components/header/LocaleSelect.vue';

import { getEnvironment } from '@/utils/generalUtils';

export default {
  name: 'App',
  components: {
    LoginStatus,
    AgentStatusList,
    AppTemplate,
    ChatNotification,
    ChatEntry,
    ErrorDisplay,
    LocaleSelect,
    NotificationPopover: () => import('@/components/NotificationPopover.vue'),
    NotificationsGuideModal: () => import('@/components/NotificationsGuideModal.vue'),
  },
  provide() {
    return {
      showNotificationsGuide: this.showNotificationsGuide,
    };
  },
  computed: {
    ...mapState('agent', { loggedIn: 'isLoggedIn' }),
    ...mapState('agent', { userDetails: 'info' }),
    ...mapState(['featureFlags']),
    ...mapGetters('chat', ['visibleIncomingChats', 'visibleOngoingChats']),
    ...mapGetters(['supchatVersion']),
    isIE11() { return navigator.userAgent.indexOf('Trident/') !== -1; },
    loadChatComp() {
      return ['home', 'incoming', 'ongoing', 'archive'].includes(this.$route.name);
    },
    env() {
      return getEnvironment();
    },
    localizationObj() {
      return {
        breadcrumbs: {
          home: this.$t('appTemplate.breadcrumbs.home'),
        },
        notifications: {
          seeNotifications: this.$t('appTemplate.notifications.seeNotifications'),
          noNotifications: this.$t('appTemplate.notifications.noNotifications'),
        },
        sidebar: {
          enableCompactSidebar: this.$t('appTemplate.sidebar.enableCompactSidebar'),
          disableCompactSidebar: this.$t('appTemplate.sidebar.disableCompactSidebar'),
        },
        userMenu: {
          buttonTitle: this.$t('appTemplate.userMenu.buttonTitle'),
          greeting: this.$t('appTemplate.userMenu.greeting'),
          logOut: this.$t('appTemplate.userMenu.logOut'),
          noName: this.$t('appTemplate.userMenu.noName'),
        },
      };
    },
    sidebarRawList() {
      return [
        {
          display: this.$t('message.pageHome'),
          linkName: 'home',
          icon: 'home',
          access: 1,
        },
        {
          display: this.$t('message.pageIncoming'),
          linkName: 'incoming',
          icon: 'phone-volume',
          access: 1,
          badge: this.visibleIncomingChats.length,
        },
        {
          display: this.$t('message.pageOngoing'),
          linkName: 'ongoing',
          icon: 'comment',
          access: 1,
          badge: this.visibleOngoingChats.length,
        },
        {
          display: this.$t('message.pageArchive'),
          linkName: 'archive',
          icon: 'archive',
          matchOn: ['archive', 'archive-single'],
          access: 1,
        },
        {
          display: this.$t('message.analyzeSection'),
          icon: 'microscope',
          access: 2,
          children: sortBy([
            {
              display: this.$t('message.pageInsights'),
              linkName: 'insightsNoDashboard',
              // icon: 'microscope',
              matchOn: ['insightsNoDashboard', 'dashboard'],
              access: 2,
            },
            {
              display: this.$t('analytics.report.pageTitle'),
              linkName: 'report',
              // icon: 'clipboard-list',
              // temporary until the old analytics is fully replaced
              access: 2,
            },
            {
              display: this.$t('message.analytics.title'),
              linkName: 'statistics',
              // icon: 'chart-column',
              // temporary until the old analytics is fully replaced
              access: 2,
            },
          ], ['display']),
        },
        {
          display: this.$t('message.pageSettings'),
          icon: 'cog',
          access: 2,
          children: sortBy([
            {
              display: this.$t('settings.prediction.title'),
              linkName: 'settings-prediction',
              access: 2,
            },
            {
              display: this.$t('vocabulary.anonymization'),
              linkName: 'settings-anonymization',
              access: 2,
            },
            {
              display: this.$t('settings.autoAssign.pageTitle'),
              linkName: 'auto-assign',
              access: 2,
            },
            {
              display: this.$t('message.pageCanned'),
              linkName: 'canned-messages',
              // icon: 'file-alt',
              access: 2,
            },
            {
              display: this.$t('vocabulary.general'),
              linkName: 'settings-general',
              access: 2,
            },
            {
              display: this.$t('settings.integrations.title'),
              linkName: 'settings-integrations',
              access: 2,
            },
            {
              display: this.$t('vocabulary.kpi'),
              linkName: 'settings-kpi',
              access: 2,
            },
            {
              display: this.$t('settings.openingHours.pageTitle'),
              linkName: 'settings-opening_hours',
              access: 2,
            },
            {
              display: this.$t('settings.languages.pageTitle'),
              linkName: 'settings-languages',
              access: 2,
            },
            {
              display: this.$t('message.waitingMessages.pageTitle'),
              linkName: 'settings-inactivity_message',
              access: 2,
            },
            {
              display: this.$t('vocabulary.widgetConfigs'),
              linkName: 'settings-widget_configs',
              access: 2,
            },
          ], ['display']),
        },
        {
          display: this.$t('vocabulary.administration'),
          icon: 'user-lock',
          access: 3,
          matchOn: ['entry-widget-none', 'entry-widget-selected', 'tenant-management', 'user-management'],
          children: sortBy([
            {
              display: this.$t('message.pageTenantConfig'),
              linkName: 'tenant-management',
              access: 3,
            },
            {
              display: this.$t('message.pageUserManagement'),
              linkName: 'user-management',
              access: 3,
            },
            {
              display: this.$t('administration.widget.pageTitle'),
              linkName: 'entry-widget',
              matchOn: ['entry-widget-none', 'entry-widget-selected'],
              access: 3,
            },
            {
              display: this.$t('vocabulary.sso'),
              linkName: 'single-sign-on',
              matchOn: ['single-sign-on'],
              access: 3,
            },
          ], ['display']),
        },
      ];
    },
    pages() {
      const topLevelPages = this.sidebarRawList
        .filter((page) => page.access === undefined || page.access <= this.userDetails.maxRole)
        .map((page) => {
          if (page.children) {
            return {
              ...page,
              children: page.children
                .filter((child) => child.access && child.access <= this.userDetails.maxRole),
            };
          }
          return page;
        });
      return topLevelPages;
    },
    userOptionPages() {
      return [
        {
          text: this.$t('userSettings.pageTitle'),
          to: { name: 'my-settings' },
        },
      ];
    },
  },
  created() {
    this.$store.dispatch('ensureFeatureFlags');
  },
  methods: {
    showNotificationsGuide() {
      this.$refs.GuideModal.displayModal();
    },
  },
};
</script>

<style>
#app {
  background-image:
    linear-gradient(to right, rgba(2,76,115,0.97), rgba(2,76,115,0.97)),
    url('./assets/background-pattern.svg');
}
#app th{
  white-space: initial;
}
.form-inline .mx-datepicker .mx-input-wrapper .form-control {
  width: 100%;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}
.mx-icon-calendar {
  display: none;
}
.form-stackable {
  margin: -0.25rem!important;
}
.form-stackable > * {
  margin: 0.25rem!important;
}
.i18n-newlines {
  white-space: pre-line;
}
.top-0 { top:0!important }
.bottom-0 { bottom:0!important }
.left-0 { left:0!important }
.right-0 { right:0!important }
</style>
