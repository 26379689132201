<template>
  <div
    v-if="ready && !sidebarCompact"
    class="px-2 mx-3 agents-list scrollbar-sidebar r-25 pb-1"
  >
    <b-button
      size="sm"
      variant="link"
      block
      class="text-muted py-0"
      @click="compact = !compact"
    >
      <FontAwesomeIcon
        :icon="compact ? 'caret-up' : 'caret-down'"
      />
    </b-button>
    <template v-if="compact">
      <div
        v-for="{ status, text } in compactStatusList"
        :key="status + text"
        class="text-agent d-flex align-items-center"
      >
        <status-indicator
          :status="status"
          class="mr-1 d-inline-block"
        />
        <b-button
          variant="muted"
          size="sm"
          class="text-white text-agent offline"
          @click="compact = false"
        >
          {{ text }}
        </b-button>
      </div>
    </template>
    <template v-else>
      <div
        v-for="agent in agentsList"
        :id="`agent${agent.id}`"
        :key="agent.id"
        class="text-agent d-flex align-items-center"
      >
        <status-indicator
          :status="agent.status"
          class="mr-1 d-inline-block"
        />
        <b-dropdown
          :disabled="maxRole < 2 || agent.is_bot || agent.id === id"
          size="sm"
          boundary="viewport"
          no-caret
          :text="agent.display_name"
          variant="muted"
          class="w-100"
          :title="agent.display_name"
          :toggle-class="`text-truncate text-white
            text-left text-agent ${agent.status === 'IN' ? 'offline' : ''}
            ${agent.is_bot ? 'text-bot' : ''}`"
        >
          <b-dropdown-header>{{ $t('message.setStatus') }}</b-dropdown-header>
          <b-dropdown-item
            v-for="option in statusOptions"
            :key="option.value"
            @click="changeAgentStatus({ agentId: agent.id, status: option.value })"
          >
            {{ option.text }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { sortBy } from 'lodash';
import StatusIndicator from '@/components/Sidebar/StatusIndicator.vue';

export default {
  name: 'AgentsStatusList',
  statusSort: {
    ON: 1,
    AW: 2,
    IN: 3,
  },
  components: {
    StatusIndicator,
  },
  data() {
    return {
      ready: false,
      compact: true,
    };
  },
  computed: {
    ...mapGetters('agent', ['maxRole', 'id']),
    ...mapGetters('systemAgents', ['systemAgents']),
    ...mapGetters('status', ['getAgentStatus']),
    ...mapState('templateStore', ['sidebarCompact']),
    agentsList() {
      const list = this.systemAgents.map((agent) => ({
        ...agent,
        status: this.getAgentStatus(agent.id),
      }));
      return sortBy(
        list.filter((agent) => agent.status !== undefined),
        [(a) => this.$options.statusSort[a.status],
          (a) => a.display_name.toLowerCase()],
      );
    },
    compactStatusList() {
      const onlineCount = this.agentsList.filter(({ status }) => status === 'ON')?.length || 0;
      const onlineSummary = {
        status: 'ON',
        text: this.$t('message.summaryOnline', {
          x: onlineCount,
          agent: this.$tc('vocabulary.agent', onlineCount).toLowerCase(),
        }),
      };
      const awayCount = this.agentsList.filter(({ status }) => status === 'AW')?.length || 0;
      const awaySummary = {
        status: 'AW',
        text: this.$t('message.summaryAway', {
          x: awayCount,
          agent: this.$tc('vocabulary.agent', awayCount).toLowerCase(),
        }),
      };
      if (!onlineCount && !awayCount) {
        return [{
          status: 'IN',
          text: this.$t('message.noneOnline'),
        }];
      }
      return [onlineSummary, awaySummary];
    },
    statusOptions() {
      return [
        {
          value: 'ON',
          text: this.$t('vocabulary.online'),
        },
        {
          value: 'AW',
          text: this.$t('vocabulary.away'),
        },
        {
          value: 'IN',
          text: this.$t('vocabulary.invisible'),
        },
      ];
    },
  },
  async created() {
    await this.ensureAgentsFetched();
    this.ready = true;
  },
  methods: {
    ...mapActions('status', ['setAgentStatus']),
    ...mapActions('systemAgents', ['ensureAgentsFetched']),
    async changeAgentStatus({ agentId, status }) {
      const response = await this.setAgentStatus({ agentId, status });
      if (!['ON', 'AW', 'IN'].includes(response)) {
        const agentName = this.agentsList.find((a) => a.id === agentId).display_name;
        this.$bvToast.toast(this.$t('message.statusChangeErrorBody', { agent: agentName }), {
          title: this.$t('message.statusChangeError'),
          autoHideDelay: 7500,
          variant: 'danger',
        });
      }
    },
  },
};
</script>

<style>
.agents-list {
  max-height: 20vh;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0.9;
  background-color: rgba(0,0,0,0.25) !important;
}
.agents-list .text-agent {
  line-height: 1!important;
}
.agents-list .offline {
  opacity: .5;
}
.agents-list .text-agent:not(.offline),
.agents-list .text-bot:not(.offline) {
  opacity: 1 !important;
}
</style>
