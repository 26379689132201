import chatTranslation from 'supwiz/supchat/translationsChat';

import administration from './administration/en.json';
import analytics from './analytics/en.json';
import appTemplate from './appTemplate/en.json';
import insights from './insights/en.json';
import settings from './settings/en.json';
import userSettings from './userSettings/en.json';

export default {
  administration,
  interaction: chatTranslation.en.interaction,
  analytics,
  appTemplate,
  connection: {
    connectionRetry: 'Retrying {time}.',
    connectionGiveUp: 'Could not reconnet.',
    connectionOk: 'Connected.',
    disconnectedServices: 'One or more services have lost connection:',
    offline: 'It looks like you are offline.\nPlease check your internet connection.',
    needsRefresh: 'One or more services could not reconnect after several attempts. SupChat will not work correctly until the page has been refreshed.',
    refreshBtn: 'Refresh Page',
    titles: {
      chatSocket: 'Chat Service',
      controlSocket: 'System Service',
      incomingSync: 'Incoming Chats Syncronization',
      ongoingSync: 'Ongoing Chats Syncronization',
    },
  },
  insights,
  settings,
  userSettings,
  status: chatTranslation.en.status,
  message: {
    doNotSave: 'Do Not Save',
    transferInfo: 'Transfer Info',
    pageLogin: 'Login',
    pageHome: 'Home',
    pageIncoming: 'New Chats',
    pageOngoing: 'Ongoing Chats',
    pageArchive: 'Archive',
    pageInsights: 'Insights',
    pageSettings: 'Settings',
    pageCanned: 'Canned Messages',
    pageTenantConfig: '@:vocabulary.tenantMultiple',
    pageUserManagement: '@:vocabulary.userMultiple',
    pageMetaTitle: '{page} | SupChat',
    greeting: 'Hi, {name}!',
    noticeboard: {
      sectionTitle: 'Noticeboard',
      noticeAdd: 'Add Notice',
      noticeDelete: 'Delete Notice',
      noticeUpdate: 'Update Notice',
      noticesNone: 'No Notices Found',
      noticesRefresh: 'Refresh Notices',
      modal: {
        color: 'Color',
        colorDesc: 'Here you can select a color for the background of the notice. This can help agents distinguish between notices.',
        colorSelect: 'Select This Color',
        content: 'Content',
        contentDesc: 'This is the content of the notice. You are also able to enter some HTML. If adding links, make sure these open in a new tab so that agents are not directed away from chats.',
        contentIllegalTags: 'One or more of the added HTML tags are not allowed.',
        contentNoClassAttribute: 'You are not allowed to use the class attribute.',
        display_from: 'Time Range',
        display_fromDesc: 'Here you can set when the notice should be displayed. When outside of the selected range, the notice is only visible to managers and above.',
        deleteConfirm: 'Are you sure that you want to delete this notice? A notice is also automatically deleted shortly after it has expired.',
        modalTitleAdd: 'Add New Notice',
        modalTitleDelete: 'Delete Notice',
        modalTitleUpdate: 'Update Notice',
        priority: 'Priority',
        priorityDesc: 'Priority is used for sorting the notices. Higher priority will be moved to the front of the list. If notices have the same priority, they are then also sorted by their title.',
        priorityLow: 'Low',
        priorityHigh: 'High',
        priorityMedium: 'Medium',
        title: 'Title',
        titleDesc: 'Set a title for the notice.',
        visible_to: 'Visible To',
        visible_toDesc: 'Here you can select which departments this notice should be shown to. Any agents that have access to a selected department, will be able to see the notice.',
      },
    },
    agentOverview: {
      addOverview: 'Add Overview',
      addOverviewDesc: 'Add an overview to see time allocation amongst selected agents.',
      awayTime: 'Time Spent @:vocabulary.away',
      handlingChatsTime: 'Time Spent Handling Chats',
      modal: {
        agentIds: 'Select Agents',
        agentIdsDesc: 'Select which agents should be included in this overview.',
        columns: 'Overview Columns',
        columnsDesc: 'Drag to arrange the order of the columns. You can also toggle which columns should be included in the overview. The Agent Name column is always visible.',
        columnToggle: 'Toggle Column',
        deleteOverview: 'Are you sure that you want to delete this overview? This cannot be undone.',
        modalTitleAdd: 'Add New Overview',
        modalTitleDelete: 'Delete Overview',
        modalTitleUpdate: 'Edit Overview',
        sortBy: 'Sorting',
        sortByDesc: 'Set which column the overview should be sorted by.',
        tenantIdDesc: 'Select which @.lower:vocabulary.tenantSingle which the overview should be based on.',
        timerange: 'Time Range',
        timerangeDesc: 'Set the start and end time for the overview. All results will be based on the selected time range.',
        timerangeUseLocal: 'Use Local Time',
        timerangeUseUTC: 'Use UTC Time',
        title: 'Overview Name',
        titleDesc: 'Enter a name for the overview.',
      },
      offlineTime: 'Time Spent @:vocabulary.offline',
      onlineTime: 'Time Spent @:vocabulary.online',
      onlineTimePerChat: '@:message.agentOverview.onlineTime (Per Chat)',
      refreshData: 'Refresh Data',
    },
    yourStats: 'Your stats',
    logoAlt: 'SupWiz Logo',
    chatsHandled: "You've handled {count} chats today!",
    depOverview: 'Department Overview',
    chatsInQueue: 'Chats in queue',
    chatsActive: 'Active chats',
    searchForMore: 'Search to see different results',
    signIn: 'Sign in',
    signInAD: 'Sign in with your organization account',
    forgottenPassword: 'Forgotten password?',
    signOut: 'Sign out',
    signOutWarning: 'You still have {number} active chat. Please consider closing or transferring it before signing out.'
      + '| You still have {number} active chats. Please consider closing or transferring them before signing out.',
    setStatus: 'Set status',
    summaryOnline: '{x} {agent} online',
    summaryAway: '{x} {agent} away',
    noneOnline: 'No agents available',
    invisibleWarn: 'You are the last online agent. Are you sure that you want to go offline?',
    invisibleReminderTitle: 'You Are Offline',
    invisibleReminderContent: 'You are currently offline which means that other agents cannot transfer chats directly to you.',
    invisibleReminderButton: 'Go Online',
    statusChangeError: 'An error occured when changing status',
    statusChangeErrorBody: 'Something went wrong when changing the status. If the error continues to happen, '
      + 'you likely do not have permission to change the status for {agent}.',
    chatsTotal: 'chats in total',
    longestWaitTime: 'Longest queue time:',
    waitTime: 'Queue Time',
    totalTime: 'Total time',
    hiddenDepartments: 'Hidden @:vocabulary.departmentMultiple',
    hiddenDepartmentsMsg: 'You have one or more hidden @.lower:(vocabulary.departmentMultiple) at the moment. This means that you will not see all chats.',
    chatsOverKPI: 'Past KPI limit',
    chatsNearingKPI: 'Nearing KPI limit',
    chatsIncoming: 'No new chats | new chats',
    chatsNoIncFound: 'No new chats found.',
    chatsNoOngFound: 'No ongoing chats found.',
    chatsOngTitle: 'Your Ongoing Chats | Other Ongoing Chats',
    chatsMy: 'My chat',
    chatsGeneral: 'New Chats',
    chatsPersonal: 'Transferred to you',
    chatAlreadyTaken: 'This chat has already been picked up.',
    chatPickupError: 'The chat could not be picked up due to an error.',
    chatPickupInsufficientPermission: 'You do not have permission to pickup this chat.',
    chatPickupRequiresOnlineStatus: 'You must change your status to @.lower:vocabulary.online before you can pick up this chat.',
    previewOfVisitorMessages: 'Preview of visitor messages',
    noVisitorMessages: 'No messages',
    searchPress: 'Press "Search" to lookup chats',
    searchFetching: 'Getting chats...',
    searchNoRes: 'No chats were found',
    searchSelectTenant: 'You need to select at least 1 tenant',
    selectBy: 'Select by',
    agentName: 'Agent Name/ID',
    curMonth: 'Current Month',
    lastMonth: 'Last Month',
    lastMonths: 'Last {number} Months',
    lastDays: 'Last {number} Days',
    lastWeek: 'Last Week',
    curWeek: 'Current Week',
    lastWeeks: 'Last {number} Weeks',
    curHour: 'Current Hour',
    lastHours: 'Last {number} Hours',
    lastHour: 'Last Hour',
    lastMinutes: 'Last {number} Minutes',
    lastMinute: 'Last Minute',
    settingsAccessDenied: 'You are not a Tenant Level Manager.\nAsk a System Administrator '
      + 'to review your access levels.',
    settingsNoTenant: 'No @:vocabulary.tenantMultiple were found.\nMake sure at least one has '
      + 'been created by a System Administrator.',
    cannedAddNew: 'Add Canned Message',
    cannedModalEdit: 'Edit Canned Message',
    cannedModalDelete: 'Delete Canned Message',
    cannedDeleteWarn: 'Are you sure you want to delete the following message?',
    cannedTenantSelect: 'Please select a tenant',
    cannedDepartmentSelect: 'All Departments',
    cannedPredict: 'Eligible For Prediciton',
    cannedPredictDesc: 'Set whether this canned message can be used by the AI Co-pilot. Note '
      + 'that this will not work if the message content contains variables.',
    cannedPermission: 'You do not have access to all departments and cannot make any changes.',
    cannedNoMessages: 'No canned messages found.',
    cannedSubjectDesc: 'Enter a title for the canned message. This will be shown in the agent\'s overview.',
    cannedContentDesc: 'Enter the message text of the canned message. It is possible to '
      + 'use variables and automatically insert e.g., the agent\'s name. This can be done by '
      + 'entering {{agent.name}} where the name should be inserted.',
    archiveSearchVisitor: 'Search by visitor name',
    archiveSearchContent: 'Search by content',
    notRated: 'Not Rated',
    allRated: 'All Ratings',
    anyComment: 'No Filtering',
    noComment: 'No Comment',
    hasComment: 'Has Comment',
    archiveToastTitle: 'Chats Deleted',
    archiveToastBody: 'Chats were deleted successfully.',
    archiveSelectTenant: 'You need to select at least 1 tenant.',
    archiveConversationBtnTxt: 'Show Conversation | Hide Conversation',
    parameterError: 'Parameter Error',
    deleteMode: 'Delete Mode',
    deleteChats: 'Delete {number} chat | Delete {number} chats',
    deleteAllChats: 'Delete ALL chats',
    visitorName: 'Customer Name',
    archiveFetchError: 'An error occurred while fetching log data. Please make sure that you typed the correct URL.',
    archiveOpenLog: 'Open Chatbot Log',
    metaInfoChat: 'Chat Meta Information',
    fieldNotDefined: '<field not defined>',
    chartTypeSelect: 'Chart Type',
    chartTypeInvalid: 'Invalid chart type',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    archive: {
      modalTitle: 'Confirm Deletion',
      modalText1: 'Chats matching the following parameters will be deleted:',
      modalText2: 'You will not be able to delete chats from Tenants which you are not a Manager of.',
      modalText3: 'Note: No time range found! Press "Cancel", readjust, and press "Search" again if this is not intended.',
      modalText4: 'Are you sure that you want to delete {number} chats? This cannot be undone.',
      modalOK: 'DELETE ALL',
      metaTitles: {
        name: '@:vocabulary.name',
        email: '@:vocabulary.email',
        tenant_name: '@:message.tenantManagement.tenantName',
      },
      timeBody: 'Time range cannot have the same start and end time',
      timeTitle: 'Invalid time range',
      exportTitle: 'Export chats',
      exportFormatDesc: 'Choose which format to export the data in.',
      exportDelimiterDesc: 'Choose the delimiter that seperates the values in the exported file.',
      exportColumnsDesc: 'Choose which columns the exported file should include.',
    },
    archiveSingle: {
      chatDetailsTitle: 'Chat Details',
      chatStatistics: 'Statistics',
      chatStarted: 'Chat Start',
      chatEnded: 'Chat End',
      visitorDetails: 'Information',
      visitorDetailsTitle: 'Visitor Details',
      customInfoFields: 'Data Fields',
      deviceInfo: '@:message.chatModal.deviceInfo',
      startLocation: 'Start Page',
      viewAs: {
        chat: 'Show conversation as chat',
        text: 'Show conversation as text',
      },
      wrongTranslation: {
        dropdownText: 'Message marked with "wrong_translation" tag',
        dropdownItemText: 'Add Manual Translation',
      },
    },
    timepickerPlace: 'Select Time Range',
    noData: 'No data',
    visitorMetaString: 'Visitor Meta Information ',
    chatModal: {
      language: {
        sectionTitle: 'Manage Language',
        agentLanguage: 'Change Your Language',
        autoTranslation: 'Auto Translation',
        autoTranslationDisable: 'Click To Disable',
        autoTranslationEnable: 'Click To Enable',
        visitorLanguage: 'Change Visitor Language',
      },
      predictions: {
        articlesGenerateResponse: 'Generate Article Response',
        articlesGenerateResponseFailed: 'Response could not be confidently generated.',
        articlesMissingLanguage: '{language} is not supported.',
        cannedMessages: '@:message.pageCanned',
        gptReply: 'Generate Reply',
        gptReplyFailTitle: 'Failed To Generate',
        gptReplyFailBody: 'A response could not be confidently generated.',
        gptReplyFlavor: {
          concise: 'Concise',
          conciseDesc: 'Regenerate to be more clear and concise.',
          formal: 'Formal',
          formalDesc: 'Regenerate to be formal and professional tone.',
          friendly: 'Friendly',
          friendlyDesc: 'Regenerate to a more friendly tone.',
          full: 'Full Response',
          fullDesc: 'Generate a full response using visitor messages as context',
        },
        metadata: 'Metadata',
        metadataPredictionSame: 'SupChat has predicted the same value.',
        noMetadataPrediction: 'SupChat has not yet found a suitable prediction for this field.',
        noPredictions: 'No predictions yet',
        selectMetaPrediction: 'Set "{fieldId}" to "{value}" ({score} confidence)',
        sentiment: 'Sentiment',
        sentimentNone: 'Predicted sentiment will be displayed here as the chat progresses.',
        summary: 'Summary',
        summaryAddAsNote: 'Add As Note',
        summaryFinal: 'Generate Final Summary',
        summaryGenerate: 'Generate',
        summaryOverlay: 'You can generate a summary below.',
        summaryRemove: 'Delete Summary',
        title: 'AI Co-pilot',
      },
      transfer: {
        queueSize: 'Chats In Queue',
        onlineAgents: 'Online Agents',
        estQueueTime: 'Estimated Queue Time',
      },
      depsOnline: 'Online Departments',
      depsOffline: 'Offline Departments',
      depsNoOnline: 'There are no online agents in this department.',
      agentsOnline: 'Online Agents',
      agentsAway: 'Away Agents',
      agentAwayWarn: 'This agent is away and may not answer the chat.',
      msgInputPlaceholderNote: 'Enter your internal note. The note will not be sent to the visitor.',
      msgInputPlaceholder: 'Enter your message. '
        + 'You can start a new line with Shift+Enter\n',
      msgInputWarning: 'Message can not be empty.',
      visitorDetails: 'Visitor Details',
      technicalDetails: 'Technical Details',
      deviceInfo: 'Device Information',
      siteInfo: 'Page Information',
      modalTitle: '{tenant}: Chat with {visitor} in {department}',
      handleTime: 'Your handling time',
      articleAssistant: 'Articles',
      articleSearch: 'Search for articles',
      articleSearchBookDropdown: {
        originalLanguage: 'Search using original message',
        translatedLanguage: 'Search using translation',
      },
      articleNoneFound: 'No articles found',
      articleRateGood: 'Article Helpful',
      articleRateBad: 'No Helpful Articles',
      articlePreview: 'Open Article',
      articleCopy: 'Copy',
      articleCopyLink: 'Link',
      articleCopySnippet: 'Snippet',
      articlePin: 'Pin the article and use it later in the conversation.',
      articleUnpin: 'Unpin and discard the article from the pinned list.',
      autocomplete: '(TAB to autocomplete)',
      questionAdd: 'Create Question',
      qModalTitle: 'Create Question Object',
      qModalPlace: 'Enter the question to ask',
      qModalOptionSelect: 'Please select an option type',
      qModalButtonSelect: 'Button Select',
      qModalOptionsAmount: 'Number of options:',
      chatBegin: 'Join Chat',
      sendAsNote: 'Send As Note',
      sendNote: 'Send Note',
      sendNotePlaceholder: 'The note will not be visible to the visitor.',
      disableEnter: 'Disable "Send with Enter"',
      minimizeChatModal: 'Minimize Chat Window',
      removeChatModal: 'Remove Chat Window',
      emailTitle: 'Send chat conversation to the customer\'s e-mail',
      emailSend: 'Send E-mail',
      emailSending: 'Sending E-mail',
      chatEnd: 'End Chat',
      chatEndConfirm: 'Are you sure you want to end the chat?',
      cannedFilter: 'Search for Canned Messages',
      cannedNoFound: 'No message found',
      inputPlace: 'Enter your message. Hold \'Shift\' and press \'Enter\' to start a new line.',
      inputWarning: 'Message cannot be empty.',
      newVisitor: 'New Visitor',
      newVisitorIn: 'New Visitor in',
      fieldRequired: 'Field must be filled in',
      visitorMetaDataNotReady: 'One or more metadata fields must be filled in before ending the chat/closing the window.',
      setTranslation: 'Set Language',
      noTranslation: 'No translation',
      fileUpload: {
        errorTooBig: 'The file is too big.',
        fileWarning: 'Only view the file if you need to.',
        sendFile: 'Send file',
        showFile: 'Show File',
      },
      formSent: 'A form was sent to the @.lower:(vocabulary.visitorSingle)',
      messageTypes: {
        chat: 'Chat Message',
        note: 'Internal Note',
        preview: 'Preview',
      },
      tenant_id: 'Tenant ID',
      tenant_name: 'Tenant Name',
    },
    analyzeSection: 'Analyze',
    analytics: {
      title: 'Statistics',
      export: 'Export Data',
      dataExport: {
        columns: 'Columns',
        columnsDesc: 'Select which columns you want to include in the export. You can also click and drag to rearrange them.',
        columnsToggle: 'Show/hide Column',
        export: 'Export',
        exportData: 'Export Data',
        exportFailed: 'Failed To Export File.',
        fileType: 'File Type',
        fileTypeDesc: 'Select which file type that the data should be exported to.',
      },
      metrics: {
        number_of_handling_chat: '@:message.analytics.metrics.chat_count',
        average_number_of_simultaneous_chat: 'Average Number Of Simultaneous Chats',
        average_chat_duration: 'Average Chat Duration (min)',
        average_handling_time: 'Average Handling Time (min)',
        chat_count: 'Total Chats',
        avg_total_waiting_time: 'Average Total Wait Time (min)',
        avg_first_waiting_time: 'Average Queue Time (min)',
        avg_total_time: '@:message.analytics.metrics.average_chat_duration',
        kpi_total_waiting_time: 'Total Handling Time KPI (%)',
        kpi_first_waiting_time: 'Average Queue Time KPI (%)',
      },
      statsAverage: 'Table Average',
      exportFailed: 'Data export failed',
      exportError: 'An error occured when trying to export the data. Please try again later.',
    },
    // Insights
    insightsPage: {
      incompleteData: 'The data is still being calculated. The chart will automatically update as the calculation progresses.',
      graphEdit: 'Edit Graph',
      graphDelete: 'Delete Graph',
      graphDelConfirm: 'Are you sure you want to delete this graph?',
      graphCreate: 'Create graph',
      graphCreateNew: 'Create New Graph',
      graphSave: 'Save Graph',
      graphAddNew: 'Add New Graph',
      nameRequired: 'A name is required.',
      nameDesc: 'Enter a name for your graph.',
      tenantDesc: 'Select which @.lower:vocabulary.tenantMultiple to include in the graph.',
      chooseLanguage: 'Choose Language',
      fuzzyMatchEnabled: 'Smart Matching',
      fuzzyMatchEnabledDesc: 'If supported and enabled, the matching may include additional words such as '
        + 'synonyms to widen the search. This can give more accurate results.',
      fuzzyMatchBaseLang: 'Smart Matching Base Language',
      fuzzyMatchBaseLangDesc: 'To work correctly, @:message.insightsPage.fuzzyMatchEnabled needs a base language.',
      fuzzyMatchSupport: 'Only the following languages are supported:',
      wordsRequired: 'Search Words',
      wordsRequiredDel: 'Delete List',
      wordsRequiredDelConfirm: 'Are you sure you want to delete this list?',
      wordsRequiredDesc: 'Here you can add lists of words or phrases for use in matching. '
        + 'In every list, there must be at least one match.',
      wordsDisallowed: 'Disallowed Words',
      wordsDisallowedDesc: 'Enter a list of words or phrases that must not be found in the chat.',
      wordsDesc: 'Enter word or phrase',
      wordsAddList: 'Add List',
      wordsInvalid: 'Invalid search term',
      wordsDuplicate: 'Search term is already in this list',
      wordsBoth: 'Search terms already exists in another list',
      wordsMatchTest: 'Test Matching',
      wordsMatchTestDesc: 'Enter a phrase or insert a chat conversation to quickly test if '
        + 'the matching works as expected.',
      languageDesc: 'Filter chats based on the visitor\'s language.',
      dashEdit: 'Edit Dashboard',
      dashCreate: 'Create Dashboard',
      dashCreateNew: 'Create New Dashboard',
      dashSave: 'Save Dashboard',
      dashDelete: 'Delete Dashboard',
      dashDelConfirm: 'Are you sure you want to delete this dashboard?',
      dashEmpty: 'Please select a dashboard or create a new one to get started.',
      timeMissing: 'A time range must be specified',
      chatStarted: 'Chat started',
      updateFilters: 'Update Filters',
      customFilters: 'Custom Data Field Filters',
      customFiltersDesc: 'Use information from Custom Data Fields to further filter results.',
      customFiltersTitle: 'Data Field',
      customFilterPlace: 'Enter search term, or "*" (without quotes)',
      customFilterSelect: 'No custom filters selected',
      customFilterAdd: 'Add Custom Filter',
      allLanguages: 'All languages',
      isPrivate: 'Private Dashboard',
      private: 'Private',
      shared: 'Shared',
    },
    // login
    loginPage: {
      title: 'SupChat Sign in',
      serverDown: 'The server is down now. Please contact your administrator or try it later.',
      invalid: 'Invalid username or password.',
      permissionDeniedSupchat: 'The user you used to sign in has not been granted permission to sign in via SupChat. Please check that you are using the correct account. If the problem persists contact your administrator.',
      unexpectedError: 'An unexpected error occurred while signing in using active directory. If the problem persists please either try using a different account or signing in in an incognito window.',
      permissionDeniedAgent: 'Your system account does not permit you to login to the agent interface. If you need to login here, please contact your system administrator.',
      unknownError: 'An unexpected error occured. Please try again later.',
      usernamePlace: 'Enter username',
      passwordPlace: 'Enter password',
    },
    // settings
    unsavedSettingsTitle: 'Unsaved Changes',
    unsavedSettingsBody: 'You have unsaved changes. How do you want to continue?',
    unsavedSettingsSave: 'Save Changes',
    unsavedSettingsCancelNav: 'Stay Here',
    unsavedSettingsDiscard: 'Discard Changes',
    somethingWrong: 'Something went wrong!',
    failedFormatted: 'Failed to display any formatted results.',
    rawResult: 'See raw result',
    errorUnexpected: 'Some unexpected error occurred.',
    permissionWarning: 'You do not have sufficient permission to edit settings. '
      + 'You may review them but you cannot make any changes.',
    kpi: {
      tenantKpiTargets: '@:vocabulary.tenantSingle KPI Targets',
      departmentKpiTargets: '@:vocabulary.departmentSingle KPI Targets',
      setDepartmentKPIs: 'Specify KPI Targets Per @:vocabulary.departmentSingle',
      setDepartmentKPIsDesc: 'Set individual KPI targets for each @.lower:(vocabulary.departmentSingle). The targets are used when a chat is in queue at the given department and when calculating statistics per @.lower:(vocabulary.departmentSingle). If KPI targets are set per @.lower:(vocabulary.departmentSingle), any measurements from other @.lower:vocabulary.departmentMultiple will not be included in the calculation for the single @.lower:(vocabulary.departmentSingle).',
      maxQueueTime: 'Max @:message.kpi.queueTime',
      maxQueueTimeDepDesc: 'Specify a target for how long a chat should, at most, be in a queue in total.',
      maxQueueTimeDesc: '@:message.kpi.maxQueueTimeDepDesc If a single chat is in a queue multiple times, the queue times are added together and the KPI result is calculated from the total.',
      maxTotalReplyTime: 'Max @:message.kpi.totalReplyTime',
      maxTotalReplyTimeDesc: 'Specify a target for how long a @.lower:vocabulary.visitorSingle should wait for a reply from an @.lower:vocabulary.agentSingle while the chat is ongoing.',
      queueTime: 'Total Queue Time',
      queueTimeDesc: 'Shows the number chats that complied with the KPI target "@.lower:(message.kpi.queueTime)". The value shows the percentage followed by the number chats that complied with the target, and finally the total number of chats in the period.',
      totalReplyTime: 'Total Reply Time',
      totalReplyTimeDesc: 'Shows the number of chats that complied with the KPI target "@.lower:(message.kpi.totalReplyTime)". The value shows the percentage followed by the number chats that complied with the target, and finally the total number of chats in the period.',
      tenant: 'Shows the name of the Tenant or Department that the values belong to.',
      today: {
        waiting_time: '@:message.kpi.queueTimeDesc',
        handling_time: '@:message.kpi.totalReplyTimeDesc',
      },
      month: {
        waiting_time: '@:message.kpi.queueTimeDesc',
        handling_time: '@:message.kpi.totalReplyTimeDesc',
      },
      averageAppend: '(Average)',
    },
    general: {
      modalHeader: 'Chat Modal Settings',
      modalAutoStart: 'Auto Join Chat',
      modalAutoStartDesc: 'Automatically join the chat when opening the chat modal.',
      modalAutoClose: 'Auto Close Chat Modal',
      modalAutoCloseDesc: 'Automatically close the chat modal when ending the chat.',
      modalTransfer: 'Transfer Chats',
      modalTransferDesc: 'Allow manual transferring of chats to other departments.',
      modalAppend: 'Append Canned Message',
      modalAppendDesc: 'Append the selected canned message to already existing text in the input field. Otherwise, it will replace exisiting text.',
      modalCustom: 'Custom Data Fields',
      modalCustomDesc: 'Create additional input fields which can be filled in by agents during a chat.',
      modalCustomCreate: 'Create field',
      modalCustomEdit: 'Edit field',
      modalCustomDelete: 'Delete this field',
      modalCustomDeleteWarn: 'Are you sure you want to delete this field?',
      modalCustomNamePlace: 'Please enter a field name',
      modalCustomNameUnique: 'A field with this name already exists',
      modalCustomEmpty: 'No custom data fields defined',
      modalCustomTypes: {
        text: 'Textbox',
        checkbox: 'Checkbox',
        select: 'Drop-down selector',
        link: 'Clickable link',
      },
      modalCustomSelectOptions: 'Drop-down options',
      modalCustomNoValues: 'No options added',
      modalCustomProtected: 'Protected',
      modalCustomProtectedDesc: 'Protect the field from being changed by agents without admin permissions',
      modalCustomRequired: 'Required',
      modalCustomRequiredDesc: 'Require agent to set this field before closing a chat',
      modalCustomTypeSelect: 'Please select a field input type',
      modalCustomOptionsInvalid: 'Please enter at least one option and no duplicates values/labels',
      modalCustomRequiredProtectedWarn: 'Field both required and protected. This will make it impossible for agents without admin-permissions to close a chat unless the field is set automatically by the system.',
      chatSettings: 'Chat Settings',
      incomingChatToDefaultDepartment: 'Set Default Department',
      incomingChatToSelectableDepartment: 'Visitor Chooses Department',
      incomingChatDepartment: 'Default Department',
      incomingChatDepartmentDesc: 'Select if all chats should be sent to the same department '
        + 'initially, or if the @.lower:vocabulary.visitorSingle can choose the department upon '
        + 'starting a chat. Departments can be excluded from the list of departments under '
        + '"@:(message.pageTenantConfig)".',
      chatDefaultNoDeps: 'No departments were found',
      chatDefaultSelectDep: 'Select Department',
      chatRating: 'Chat Rating',
      chatRatingDesc: 'Allow visitors to rate the chat after the chat has ended.',
      chatRatingOptions: 'Number Of Options',
      chatRatingOptionsDesc: 'Set the number of options to choose from.',
      chatRatingText: 'Prompt Message',
      chatRatingTextDesc: 'Set a text to display in the rating prompt.',
      chatRatingConfirmationText: 'Confirmation Message',
      chatRatingConfirmationTextDesc: 'Enter a thank you message which will displayed to the visitor after they submit a rating. '
        + 'If left blank, the rating prompt will simply disappear after a rating is submitted.',
      chatRatingAllowComments: 'Allow Visitor Comments',
      chatRatingAllowCommentsDesc: 'Allow the visitor to include a comment with their rating.',
      chatRatingCommentPlaceholder: 'Comment Input Field Placeholder',
      chatRatingCommentPlaceholderDesc: 'Enter a placeholder text for the comment input field.',
      chatRatingAgentArchiveSearch: 'Display Ratings to Agents on @:message.pageArchive page',
      chatRatingAgentArchiveSearchDesc: 'Allow agents to search for and see ratings on the @:message.pageArchive page. If disabled, this functionality is restricted to Managers.',
      chatInactive: 'Close Inactive Chats',
      chatInactiveDesc: 'Chats, that have been inactive for longer than the specified time, will '
        + 'close automatically.',
      chatInactiveInvalid: 'The selected value must be between 1 minute and 12 hours.',
      chatInactiveAfter: 'Close After',
      transcriptEnable: 'Enable Transcript Via Email',
      transcriptDesc: 'Allow the visitor to request a transcript of the chat via email. The transcript will be sent from "noreply@supwiz.com".',
      apiHeader: 'API Services',
      apiEmail: 'E-mail Service',
      apiEmailDesc: 'Set up an e-mail API for sending transcripts by e-mail.',
      apiEndpoint: 'Endpoint',
      apiAuth: 'Auth token',
      apiAuto: 'Send e-mails automatically',
      inactivityTimeout: 'Agent Inactivity',
      inactivityTimeoutDesc: 'Set if agents should automatically be set to away after the chosen amount of time. If an agent belongs to multiple tenants, the minimum value is used.',
      visitorAuthEnabled: 'Require Login For Visitors',
      visitorAuthEnabledDesc: 'Require visitors to be logged in before they can start a chat.',
      visitorFileUpload: '@:vocabulary.visitorSingle File Upload',
      visitorFileUploadDesc: 'Allow @.lower:vocabulary.visitorMultiple to upload and share files with @.lower:vocabulary.agentMultiple during a chat.',
      pickupChatRequiresOnlineStatus: 'Require Online Status On Chat Pickup',
      pickupChatRequiresOnlineStatusDesc: 'Select if a status of @.lower:vocabulary.online is required before any chats can be picked up.',
    },
    anonymization: {
      enable: 'Chat Anonymization',
      enableDesc: 'Select how you want to anonymize data in SupChat. '
        + 'The Data Targets chosen below will be used in the anonymization.',
      enableDatabase: 'In Database',
      enableDatabaseDelayed: 'In Database, Delayed',
      enableDatabaseWarning: 'When anonymizing in the database, any anonymized data will be '
        + 'lost and cannot be restored.',
      anonymizeMetaData: 'Visitor Anonymization',
      anonymizeMetaDataDesc: 'Set if visitor metadata should also be anonymized. '
        + 'If disabled, SupChat will only anonymize the chat conversation and '
        + 'any information gathered about the visitor will not be anonymized.',
      anonymizeAfter: 'Anonymize After',
      anonymizeAfterInvalid: 'Anonymization must take place before any scheduled deletion.',
      visitorDataTargets: 'Use Data Targets',
      visitorFull: 'All Visitor Data',
      anonymizeOpenAI: 'Anonymize GPT Calls',
      anonymizeOpenAIDesc: 'Choose whether the data the AI Copilot uses to generate responses should be anonymized before the response is generated.',
      scheduledDeletion: 'Scheduled Deletion',
      scheduledDeletionDesc: 'Schedule data deletion which will delete chat conversations after a set amount of days.',
      saveWarn: 'These settings will affect up to {chatCount} chats. Please confirm the changes by entering "{chatCountRaw}" below.',
      saveWarnInputPlaceholder: 'Enter "{chatCountRaw}"',
      saveWarnInputInvalid: 'The entered value does not match the number of affected chats.',
      deleteAfter: 'Delete After',
      deleteAfterInvalid: 'Chats can only be deleted after at least 1 day and not before being anonymized, if enabled.',
      dataTargets: 'Data Target | Data Targets',
      dataTargetsDesc: 'Select what data you want to anonymize.',
      dataNoTargets: 'No data targets found',
      dataAddNew: 'Add New Data Target',
      dataEdit: 'Edit Data Target',
      dataDelete: 'Delete Data Target',
      dataAddFailed: 'Failed to create',
      dataEditFailed: 'Failed to edit',
      dataDeleteFailed: 'Failed to delete',
      dataAddAuth: 'You are not authorized to add this type of data target.',
      dataEditAuth: 'You are not authorized to edit this type of data target.',
      dateDeleteAuth: 'You are not authorized to delete this data target.',
      dataReplaceInform: '* will be replaced by a number',
      dataNoSelectedType: 'Please select a type',
      dataRegexPlaceholder: 'Enter a case-insensitive Regular Expression here.',
      dataRegexEmpty: 'Please write a regex with 1 capture group',
      dataRegexInvalid: 'Regex must be valid',
      dataRegexLoop: 'Regex must not match on @.lower:(message.anonymization.dataBuiltinReplace).',
      dataRegexGroups: 'Regex must contain exactly 1 capture group. Hint: Start inner capture groups with "(?:" to make them none-capture groups.',
      dataBuiltinSelect: 'Please select a target',
      dataBuiltinReplace: 'Replacement Text',
      dataBuiltinReplaceEmpty: 'Enter a replacement text which will replace the anonymized data.',
      dataBuiltinReplaceDesc: 'Will show as <{text}_*>',
      dataBuiltinReplacePlaceholder: 'Text for replacing the info detected by the data target.',
      dataBuiltinReplaceNoTextWarn: 'Your data target must have a replacement text.',
      dataBuiltinReplaceAlphaNum: 'The replacement text must only consist of numbers and letters.',
      dataDeleteWarn: 'Are you sure you want to delete this data target?',
      dataOptions: {
        // types
        regex: 'Regular Expressions',
        targeter: 'Built-in Targeters',
        // targets
        visitorName: 'Visitor Name',
        fullName: 'Full Names',
        visitorAuthToken: 'Visitor Auth Token',
        domain: 'Domains',
        email: 'E-mails',
        ip: 'IP Addresses',
        phone: 'Phone Numbers',
        address: 'Addresses',
        cpr: 'CPR Numbers',
      },
      restrictedToSysAdmins: 'Can only be edited by system admins',
    },
    integrations: {
      noIntegrations: 'No integrations were found.',
      addIntegration: 'Add Integration',
      saveError: 'Some errors occurred when saving the integration. Please rectify the mistakes and try again.',
      createTitle: 'Creating New Integration',
      editTitle: 'Editing Integration {name}',
      deleteTitle: 'Are you sure you want to delete the "{integrationName}" integration?',
      namePlaceholder: 'Enter Name',
      nameDesc: 'Enter a name for the integration which will be displayed to the agent.',
      endpointDesc: 'The endpoint that should be reached. Should start with http:// or https://',
      methodDesc: 'Select which type of integration this must be.',
      addParameter: 'Add Parameter',
      parameterDesc: 'Add parameters to your integration. Editable parameters are able to use '
        + 'variables as value. E.g., if you have a visitor meta field named "id" you can set '
        + 'the value to {{visitor.id}} and the value will automatically be inserted.',
      parameterNamePlaceholder: 'Name of parameter',
      parameterNameError: 'Parameters must have unique names.',
      parameterValuePlaceholder: 'Default value of parameter',
      isEditable: 'Is Editable',
      isHeader: 'Is Header',
      visibleDesc: 'Set if the integration should be visible to agents.',
      timeoutDesc: 'The timeout in seconds before the integration is cancelled.',
      output: 'Output formatting',
      outputDesc: 'Input via the <a href="https://handlebarsjs.com/guide/#simple-expressions" '
        + 'target="_blank">Handlebars Template Language</a> how the output of the integration '
        + 'should be formatted. Accepts HTML.',
      outputPlaceholder: 'Result was {{ data }}',
      testIntegration: 'Test Integration',
      formattedResult: 'Formatted result:',
    },
    widgetConfig: {
      errors: {
        required: 'This cannot be blank and must be specified.',
        unique: 'This is already taken.',
        failedToLoadConfig: 'Could not load the selected config.',
        fileSize: '{name} is {size} and cannot be more than {maxSize}.',
        noDefaultDepartment: 'The visitor will not be able to start a valid chat because there is no default department configured under \'@:vocabulary.general\' settings. You must adjust this setting or re-enable \'@:(message.widgetConfig.key.show_start_page)\'.',
      },
      fontSize: 'Font Size',
      selectConfig: 'Select a widget config',
      deleteConfig: 'Delete Config',
      deleteConfigConfirm: 'Are you sure you want to delete this config? This cannot be undone.',
      createConfig: 'Create Config',
      configTemplate: 'Config Template',
      configTemplateDesc: 'Choose a template or another config to start from.',
      noConfigs: 'There are no widget configs for this tenant.\nCreate one to configure the widget.',
      noConfigSelected: 'There is no selected widget config.\nSelect one above on the right.',
      cacheWarningMessage: 'Changes may take up to a minute to appear. If they are not showing, make sure your browser has not cached a previous configuration.',
      colorContrast: {
        danger: 'The contrast ratio between the selected colors is very low. Many users will have trouble reading the text.',
        warning: 'The contrast ratio between the selected colors is low. Some users will have trouble reading the text.',
      },
      configName: 'Config Name',
      configNameDesc: 'This is the internal name of your config.',
      configNoName: 'The config must have a name.',
      configUniqueError: 'Config names must be unique within the tenant.',
      configId: 'Config ID',
      configIdDesc: 'This is the ID of your config.',
      previewWidget: 'Preview Widget',
      codeNote: 'This code can be pasted anywhere within the body tag, but must be present on all pages where the widget should be available.'
        + ' To programmatically modify values, you must pass a function as the first argument '
        + 'to the \'configureWidget\' function. Your function will be called by \'configureWidget\' '
        + 'with the current configuration object as the first argument, allowing your function to '
        + 'make changes. Your function must then return the modified configuration object. '
        + 'Contact SupWiz for additional documentation.',
      codeBeginning: 'Beginning of SupChat widget files',
      codeEnd: 'End of SupChat widget files',
      chatPreviewAvatarsNote: 'Avatars are not previewed.',
      chooseNewIcon: 'Choose New Icon',
      selectFile: 'Select File',
      insertUrl: 'Insert URL',
      titleWelcomePage: 'Start Page',
      titleChatPage: 'Chat Page',
      titleLayoutStyling: 'Layout & Styling',
      titleOther: 'Other Settings',
      sectionWelcomePage: 'Setup',
      sectionInputFields: 'Input Fields',
      sectionChatLayout: 'Chat Layout & Colors',
      sectionAvatars: 'Avatar Setup',
      sectionMsgInputField: 'Message Input Field',
      sectionRatingOptions: 'Rating Options',
      sectionAdditionalOptions: 'Additional Options',
      sectionWidgetHeader: 'Header',
      sectionColorProfile: {
        title: 'Color Profile',
        background: 'Background Color',
        text: 'Text Color',
        primary: 'Primary Colors',
        primaryDesc: 'The primary colors are used in the widget header as well as in the chat rating prompt.',
        secondary: 'Secondary Colors',
        secondaryDesc: 'The secondary colors are used in interactive elements such as buttons and input fields. Input fields will use the chosen background color as the border when in focus.',
      },
      sectionFont: 'Widget Font',
      sectionToggleButton: 'Widget Toggle Button',
      sectionLanguage: 'Widget Language',
      sectionOutsideOpeningHours: 'Outside Opening Hours',
      sectionLoadSettings: 'Load Settings',
      sectionWidgetPopup: 'Popup',
      sectionProactivePrompts: 'Proactive Prompts',
      sectionCookieSettings: 'Advanced Cookie Settings',
      sectionOverwrites: 'Overwrites',
      sectionMobileOverrides: 'Config Overrides',
      sectionInformation: 'Additional Information',
      injectMetaData: 'Note: If you want to inject values into the visitor information, you must place this part '
        + 'of the config in the HTML of your page.',
      layout: {
        top: 'Top',
        bottom: 'Bottom',
        left: 'Left',
        right: 'Right',
        height: 'Height',
        width: 'Width',
        size: 'Size',
        sizeDesc: 'Set a height and width for the widget popup. On devices with small screens, the widget will always take up the entire screen.',
        position: 'Positioning',
        positionDesc: 'Adjusting the positioning of the widget on the screen. Note that the button, which opens and closes the widget, will always be placed in the bottom right corner. If you want a different behaviour, you must implement a custom button and disable "@:(message.widgetConfig.key.insert_button)".',
      },
      key: {
        title: 'Header Title',
        titleDesc: 'Set a title which will be displayed in the header of the widget.',
        show_start_page: 'Show Welcome Page',
        show_start_pageDesc: 'Control whether the widget should display a welcome page, allowing the '
          + 'visitor to read privacy policies and input their details. If disabled, the chat will start '
          + 'immediately after clicking the widget toggle button. \'@:message.general.incomingChatToDefaultDepartment\' must be chosen under \'@:vocabulary.general\' settings.',
        show_start_pageDisabled: 'These settings are not available when \'@:message.widgetConfig.key.show_start_page\' is disabled.',
        image: {
          src: 'Welcome Page Logo',
          srcDesc: 'Display a logo on the Welcome Page. You can also a direct URL to an image file.',
          style: 'Logo Styles',
          styleDesc: 'Add inline styles to the logo, e.g. height or width. Every style must be marked with \'!important\'. Otherwise, they will be overridden.',
        },
        subtitle: 'Subtitle',
        subtitleDesc: 'Short introductory text on the welcome page.',
        disclaimer: 'Body Text',
        disclaimerDesc: 'Body text for the welcome page. You can also use HTML to create links, lists, and more.',
        visitor_can_select_department: '@:vocabulary.visitorSingle Selects Department',
        visitor_can_select_departmentDesc: 'Allow the @.lower:vocabulary.visitorSingle to select which department their chat should be sent to.',
        visitor_info_fields: {
          visitor_info_fields: 'Input Fields',
          visitor_info_fieldsDesc: 'Add input fields which the @.lower:vocabulary.visitorSingle can fill out before starting the chat.',
          visitor_info_fieldsNone: 'No @.lower:message.widgetConfig.key.visitor_info_fields.visitor_info_fields have been added yet.',
          autocomplete: 'Autocomplete Attribute',
          autocompleteDesc: 'This will set the \'autocomplete\' attribute.',
          name: 'Input Name',
          nameDesc: 'The name of the input field. This will set the id attribute value.',
          selectOptions: 'Options',
          selectOptionsDesc: 'Add one or more options to the dropdown. If you do not set any text value then the value will be displayed instead. If you create a single option with a blank value, this will selected by default unless otherwise specified.',
          selectOptionsMaxOneBlank: 'You can only have one option with a blank value.',
          selectOptionsBlankIsFirst: 'The option with the blank value must be first in the list.',
          selectOptionsMinLength: 'At least one option must be created.',
          type: 'Input Type',
          typeDesc: 'The type of the input field. This will set the type attribute value.',
          defaultValue: 'Default Value',
          defaultValueDesc: 'Set a default value for the field. It is also possible to enter JavaScript.',
          label: 'Input Label',
          labelDesc: 'Enter a label for the input field. This will be displayed above the input field.',
          placeholder: 'Input Placeholder',
          placeholderDesc: 'Placeholder text of the input field. This will set the placeholder attribute value.',
          required: 'Input Is Required',
          requiredDesc: 'Input is required. This will set the required attribute value.',
          deleteConfirm: 'Are you sure you want to delete this input field?',
          types: {
            checkbox: 'Checkbox',
            email: '@:vocabulary.email',
            hidden: 'Hidden',
            number: 'Number',
            select: 'Dropdown',
            tel: 'Telephone',
            text: 'Text',
          },
        },
        visitor_info_display_name: 'Display Name',
        visitor_info_display_nameDesc: 'This will be displayed as the visitor\'s name in the widget. You can use the value from an input field, or enter a fixed value.',
        visitor_info_display_nameSelectField: 'Select Field',
        visitor_info_display_nameType: {
          fromField: 'Input Field',
          plainText: 'Fixed Value',
          plainTextPlaceholder: 'E.g. Visitor, or #name to reference a field called name',
        },
        input_placeholder: 'Input Field Placeholder',
        input_placeholderDesc: 'The placeholder text when the visitor\'s text input field is empty.',
        send_msg_btn: 'Custom Send Message Button Styles',
        send_msg_btnDesc: 'Customize the send message button with inline styles. All styles must be '
          + 'marked as \'!important\', or they will be overridden by default styling. '
          + 'Ex: \'background:red!important;color:black!important;\'.',
        send_msg_text: 'Send Message Button Text',
        send_msg_textDesc: 'Set a text to display inside of the button, used to send messages. If left blank, an icon will be displayed instead.',
        send_with_return: 'Send Messages With \'Return\'',
        send_with_returnDesc: 'Set if hitting \'Return\' should send the currently entered text, or if it should '
          + 'create a line break instead.',
        show_waiting_info: 'Display Queue Information',
        show_waiting_infoDesc: 'Set whether the queue position will be shown to the visitor.',
        styles: {
          sender_colors: 'Message Meta Colors',
          sender_colorsDesc: 'Change the color of message meta information such as sender name and timestamp.',
          chat_font_color: 'Message Font Colors',
          chat_font_colorDesc: 'Change the font color inside of message bubbles.',
          chat_agent_color: 'Message Bubble Colors',
          chat_agent_colorDesc: 'Change the background color of the message bubbles.',
          chat_visitor_color: '@:message.widgetConfig.key.styles.chat_agent_color',
          round_bubbles: 'Rounded Message Bubbles',
          round_bubblesDesc: 'Set if chat bubbles will have rounded corners.',
          font_sizes: {
            general: 'Base Font Size',
            generalDesc: 'Set the font size in chat bubbles. This also affects the font size in prompts '
              + 'such as when the user is trying to end the chat.',
            inputs: 'Input & Button Font Size',
            inputsDesc: 'Set the font size of input fields and buttons in the widget. E.g. input fields on the welcome page.',
          },
          avatars: {
            agent: 'Default @:vocabulary.agentSingle Avatar',
            agentDesc: 'Set the default avatar for all @.lower:(vocabulary.agentMultiple). If this is not set'
              + ', @.lower:vocabulary.agentMultiple will not have an avatar by default.',
            visitor: 'Default @:vocabulary.visitorSingle Avatar',
            visitorDesc: 'Set the default avatar for all @.lower:(vocabulary.visitorMultiple). If this is not set'
              + ', @.lower:vocabulary.visitorMultiple will not have an avatar by default.',
            individual: 'Individual Avatars',
            individualDesc: 'Here you can add avatars to each of the @.lower:vocabulary.agentMultiple found in SupChat. '
              + 'If no avatar is set for an @.lower:vocabulary.agentSingle then the "@:message.widgetConfig.key.styles.avatars.agent" '
              + 'avatar will be used instead. If this is not set either then no avatar will be shown.',
          },
          ratingDisabled: '@:message.general.chatRating is currently disabled and must be enabled under \'@:vocabulary.general\' settings '
            + 'before you can access these settings.',
          rating_icon: 'Rating Icon',
          rating_iconDesc: 'Set the icon used for rating the chat after it has been ended. If one image is given, '
            + 'all rating options will have the same icon. Otherwise, an icon can be added for each individual rating icon.',
          ratingIconSingle: 'Single Icon',
          ratingIconMultiple: 'Icon Per Rating',
          rating_highlight_others: 'Highlight Up To Selected Rating',
          rating_highlight_othersDesc: 'With this enabled, selecting 4 would highlight 1 through 3 as well. '
            + 'However, if the rating is set up as a like/dislike option, the highlighting is likely not wanted and can be disabled.',
          colors: 'Widget Colors',
          colorsDesc: 'Set a primary and secondary color for the widget. These will be used across the widget for e.g., buttons, the header, and more.',
          font_family: 'Font Family',
          font_familyDesc: 'Set a font family that should be used in the widget. Fonts are not being imported automatically '
            + 'so you must make sure that the font is available on your web page.',
          interactive_elements_radius: 'Rounded Corners - Interactive Elements',
          interactive_elements_radiusDesc: 'Set the border radius of interactive elements',
          widget_bordered: 'Colored Border',
          widget_borderedDesc: 'Set if the widget popup should have a colored border. The primary background color will be shown.',
          widget_rounded_corners: 'Rounded Corners',
          widget_rounded_cornersDesc: 'Set if the widget popup should have rounded corners.',
          option: 'Overwrite Multiple Choice Styling',
          optionDesc: 'If enabled, you have the option to change e.g. the colors of the buttons.',
        },
        allow_quick_restart: 'Display Quick Restart Button',
        allow_quick_restartDesc: 'Enables the visitor to quickly refresh or start a new chat.',
        allow_quick_restartDisabled: '\'@:(message.widgetConfig.key.allow_quick_restart)\' will only work if \'@:(message.widgetConfig.key.show_start_page)\' is disabled.',
        insert_button: 'Insert Widget Toggle Button',
        insert_buttonDesc: 'Insert a button to open and close the widget. If you wish to create your own button, you can disable '
          + 'this option. To toggle the widget with a custom button, you can do "onclick="widgetObj.then(o => o.toggleWidget());"".',
        custom_chat_button_icon: 'Button Icon',
        custom_chat_button_iconDesc: 'Change the image used for the widget toggle button.',
        chat_button_focus_style: 'Focus Styles',
        chat_button_focus_styleDesc: 'Add focus styles to the chat toggle button.',
        chat_button_label: 'Button Text',
        chat_button_labelDesc: 'Set a text that will be used as the "title" and "aria-label" attribute. This is used by screen readers and more.',
        language: 'Widget Language',
        languageDesc: 'Select the language of the widget UI.',
        translations: 'UI Texts',
        translationsDesc: 'Override texts in e.g. buttons or other elements.',
        hide_outside_open_hours: 'Hide Outside Opening Hours',
        hide_outside_open_hoursDesc: 'Hides the widget toggle button outside opening hours. This will not affect chats that were '
          + 'started inside opening hours. If a page with the widget was loaded within the opening hours, the widget toggle button will remain '
          + 'visible until the user navigates or refreshes the page. '
          + 'It will not be possible to start a chat because the widget will display the message and opening hours as configured below.',
        outside_opening_hours_message: 'Closed Message',
        outside_opening_hours_messageDesc: 'This message will be displayed to the visitor when they open the widget outside opening hours.',
        list_daily_opening_hours_count: 'List Daily Opening Hours',
        list_daily_opening_hours_countDesc: 'Set how many days to show opening hours for. If 0 is entered, the list will not be shown.',
        start_open: 'Start Open',
        start_openDesc: 'Set if the widget should open automatically when the page loads. This setting is ignored on mobile device and will always be off.',
        start_if_ongoing_chat: 'Open If @:vocabulary.visitorSingle Has Ongoing Chat',
        start_if_ongoing_chatDesc: 'Set if the widget should automatically open if an ongoing chat is detected. This setting is ignored on mobile device and will always be off.',
        allow_notifications: 'Enable Sound Notifications',
        allow_notificationsDesc: 'Allow @.lower:vocabulary.visitorMultiple to receive audible notifications whenever they receive a message.',
        preload: 'Preload Files',
        preloadDesc: 'This will preload all the required JS and CSS files needed for the widget. This results in a slower initial load time but '
          + 'adds additional functionality. When disabled, the files will only be downloaded if the user opens the widget.',
        proactive: {
          preloadDisabled: '\'@:message.widgetConfig.key.preload\' must be enabled before you can access '
            + 'settings for @:message.widgetConfig.sectionProactivePrompts',
          prompts: 'Prompts',
          promptsDesc: 'Add one or more proactive prompts that will be displayed to the user on specified pages.',
          promptsNone: 'No @.lower:message.widgetConfig.key.proactive.prompts have been added yet.',
          promptsDeleteConfirm: 'Are you sure you want to delete this prompt?',
          position: {
            position: 'Prompts Positioning',
            positionDesc: 'Position of the prompt message. If using a custom button to toggle the widget, '
              + 'you must choose \'@:message.widgetConfig.key.proactive.position.custom\'.',
            top: 'Above the toggle widget button',
            left: 'Next to the toggle widget button',
            custom: 'Custom position',
          },
          customPosition: 'Custom Position Styles',
          customPositionDesc: 'Apply styles to size and position the prompts. Styles must be marked as \'!important\', '
            + 'or they will be overriden. Styles will be applied as inline styling.',
          allowMobile: 'Display Prompts On Mobile Devices',
          allowMobileDesc: 'Determines if prompt messages should be shown on mobile devices.',
          reset: 'Reset Cooldown',
          resetDesc: 'To prevent displaying the same prompts more than once, a cooldown is used to control '
            + 'how often a prompt can be displayed. This will reset when the session ends or when the cooldown runs out.'
            + 'The value is in minutes.',
          message: 'Message Text',
          messageDesc: 'The prompt text that will be displayed to the user.',
          urlMatch: 'URL Matching',
          urlMatchDesc: 'Select if the page URL should match exactly or simply contain the entered value.',
          url: 'URLs Or Paths',
          urlDesc: 'Define the paths or URLs to where on the site the prompt can be displayed.',
          urlPlaceholder: 'Enter URL or path',
          showAfter: 'Show After',
          showAfterDesc: 'Delay in seconds between the user visiting the page and the prompt being shown.',
          showFor: 'Show For',
          showForDesc: 'How many seconds the prompt will be displayed for.',
          showWidget: 'Type Of Prompt',
          showWidgetDesc: 'Select what should happen when a prompt is activated.',
          showWidgetShowMessage: 'Display Message',
          showWidgetShowWidget: 'Open Widget',
          exact: 'Exact URL',
          exactUrl: 'Match URL exactly',
          looseUrl: 'URL must include',
          styles: {
            colors: 'Color Settings',
            colorsDesc: 'Set the font color and background color in @.lower:(message.widgetConfig.key.proactive.prompts).',
            fontColor: 'Font Color',
            backgroundColor: 'Background Color',
            font: 'Font Size',
            fontDesc: 'Set font size in @.lower:(message.widgetConfig.key.proactive.prompts).',
            roundedCorners: 'Rounded Corners',
            roundedCornersDesc: 'Set if the corners of @.lower:(message.widgetConfig.key.proactive.prompts) should be rounded.',
            otherStyles: 'Other Styles',
            otherStylesDesc: 'Enter additional styles for @.lower:(message.widgetConfig.key.proactive.prompts). '
              + 'Every style must be marked with \'!important\'. Otherwise, they will be overridden.',
          },
          preview: 'Preview @:message.widgetConfig.key.proactive.prompts',
          previewDesc: 'Custom position is not previewed.',
        },
        cookie_domain: 'Cookie Domain',
        cookie_domainDesc: 'Set the domain of the SupChat widget cookie. This is required when displaying the subdomains. '
          + 'In most cases, this can be left blank.',
        only_functional_cookies: 'Only Required Functional Cookies',
        only_functional_cookiesDesc: 'Prevent the widget from setting any cookies that are not '
          + 'required for the widget to function. This will disable some functionality of '
          + 'the widget, but @.lower:vocabulary.visitorMultiple will still be able to chat.',
        only_functional_cookiesWarn: 'This functionality will not work when '
          + '"@:(message.widgetConfig.key.only_functional_cookies)" is enabled.',
        css_overwrites: 'Insert CSS',
        css_overwritesDesc: 'Write custom CSS overwrites which will allow you to customize the widget beyond the available '
          + 'options. This can give unwanted results and should be used carefully. Future widget updates may change classes '
          + 'or structure which can break the overwrites. Every style must be marked '
          + 'with \'!important\'. Otherwise, they will be overridden.',
        mobile: 'Mobile Overrides',
        mobileDesc: 'See and manage overrides of the config when the widget is displayed on a small device.',
        mobileNone: 'Current no mobile overrides have been added.',
        menu_points: 'Extra Menu Points',
        menu_pointsDesc: 'Add additional menu points to the widget where you can display extra information to the @.lower:(vocabulary.visitorSingle) that they might find relevant.',
        menu_pointsModal: {
          modalTitle: 'Menu Point',
          modalDeleteTitle: 'Delete Menu Point',
          modalDeleteBody: 'Are you sure that you want to delete the menu point?',
          content: 'Content',
          contentDesc: 'When the menu point is selected, this is what will be displayed to the @.lower:vocabulary.visitorSingle in a dialog element. You can use HTML tags for the content.',
          title: 'Title',
          titleDesc: 'This is what will be displayed in the menu.',
        },
        menu_pointsNone: 'No extra menu points found',
      },
      mobileOverrideAdd: 'Add Override',
      mobileOverrideLabel: 'Field To Override',
      mobileOriginalValue: 'Original Value',
      mobileOverrideValue: 'Mobile Value',
      mobileOverrideModals: {
        deleteTitle: 'Delete Mobile Override',
        deleteBody: 'Are you sure that you want to delete the override?',
        editTitle: 'Edit Mobile Override',
        addDesc: 'Choose which setting that should be overriden on mobile devices.',
      },
    },
    waitingMessages: {
      pageTitle: 'Waiting Messages',
      inactivityMessage: 'Inactivity Message',
      inactivityMessagePlural: 'Chat Inactivity Messages',
      inactivityEnable: 'Enable @:message.waitingMessages.inactivityMessagePlural',
      inactivityEnableDesc: 'Toggle whether inactivity messages should be send to the @.lower:(vocabulary.visitorSingle). Inactivity messages will be sent every time the chat inactivity timer exceeds the chosen limit.',
      inactivityTableDesc: 'Add, edit, and delete @.lower:(message.waitingMessages.inactivityMessagePlural).',
      inactivityNoMessages: 'No @.lower:message.waitingMessages.inactivityMessagePlural defined',
      inactivityAddTitle: 'Add New @:message.waitingMessages.inactivityMessage',
      inactivityEditTitle: 'Edit @:message.waitingMessages.inactivityMessage',
      inactivityDeleteTitle: 'Delete @:message.waitingMessages.inactivityMessage',
      inactivityWillNotDisplay: 'The chat will be automatically closed before some messages are shown. \'@:message.general.chatInactive\' can be found in \'@:vocabulary.general\'.',
      deleteConfirm: 'Are you sure you want to delete this message?',
      content: 'Message Text',
      contentDesc: 'Enter a message that will be displayed to the @.lower:(vocabulary.visitorSingle).',
      contentRequired: 'The message cannot be blank.',
      timing: 'Delay',
      timingDesc: 'Determine how many seconds should pass before the message is shown.',
      timingRequired: 'The field cannot be empty.',
      timingWillNotDisplay: 'The chat will be automatically closed before this message is displayed.',
      repeatDesc: 'If enabled, the message will be displayed every X seconds. If disabled, the message will only be shown once after the delay is reached.',
      enabledDesc: 'Use this to disable and reenable messages without deleting them.',
      queueMessage: 'Queue Message',
      queueMessagePlural: 'Queue Messages',
      queueEnable: 'Enable Additional @:message.waitingMessages.queueMessagePlural',
      queueEnableDesc: 'Toggle whether you want to send additional @.lower:message.waitingMessages.queueMessagePlural to the @.lower:vocabulary.visitorSingle '
        + 'while they are waiting in a queue.',
      queueTableDesc: 'Add, edit, and delete @.lower:(message.waitingMessages.queueMessagePlural).',
      queueNoMessages: 'No @.lower:message.waitingMessages.queueMessagePlural defined',
      queueAddTitle: 'Add New @:message.waitingMessages.queueMessage',
      queueEditTitle: 'Edit @:message.waitingMessages.queueMessage',
      queueDeleteTitle: 'Delete @:message.waitingMessages.queueMessage',
    },
    tenantManagement: {
      addNew: 'Add New Tenant',
      addError: 'Tenant name cannot be blank or already taken.',
      addToastTitle: 'Tenant Created',
      addToastBody: '{name} was created successfully.',
      addToastErrorBody: 'The tenant could not be created. This is typically caused by another'
        + ' tenant already having the same name or having the same name if special'
        + ' characters are ignored.',
      noTenant: 'No Tenant selected',
      filter: 'Search departments',
      noDep: 'No departments exist. Add one below',
      noDepSearch: 'No matching departments found.',
      depAddSuccess: 'The department was created successfully.',
      depAddError: 'The department could not be created. This is typically caused by'
        + ' another department already having the same name or having the same name if special'
        + ' characters are ignored.',
      depDelSuccess: 'The department was deleted.',
      namePlaceholder: 'Enter new Department name',
      depName: 'Department Name',
      depNameDesc: 'This is the name of the selected department.',
      depID: 'Department ID',
      depIDDesc: 'This is the ID of the selected department.',
      depDelete: 'Delete Department',
      depDeleteBody: 'You are about to delete the department: {name}',
      depDeleteAccessWarnBody: 'Access to this department must first be revoked from the '
        + 'following agents: {agents}',
      depSelectable: 'Selectable By @:vocabulary.visitorMultiple',
      depSelectableDesc: 'Choose whether @.lower:vocabulary.visitorMultiple will be able to '
        + 'select this department if "@:(message.general.incomingChatToSelectableDepartment)"'
        + ' is enabled.',
      displayInTransferMenu: 'Show In Transfer Menu',
      displayInTransferMenuDesc: 'Select if @.lower:vocabulary.agentMultiple should be able to transfer to this department.',
      depUpdate: 'Update Department',
      depUpdateSuccess: 'The department was updated.',
      settings: 'Tenant Settings',
      tenantName: 'Tenant Name',
      tenantNameDesc: 'This is the name of the currently selected Tenant.',
      deleteTenant: 'Delete {name}',
      deleteTitle: 'Delete Tenant',
      deleteBody: 'Please delete the following departments first before deleting the tenant:',
      deleteConfirm: 'Are you sure you want to delete {name}?',
      deleteToastTitle: 'Tenant deleted',
      deleteToastBody: 'The Tenant was deleted successfully.',
      adGroups: 'AD Groups',
      adFilter: 'Filter by group ID',
      adNoGroups: 'No AD groups. Add one below',
      adUntitled: 'Untitled group',
      adNewID: 'Insert new group ID',
      adAdd: 'Add group',
      adID: 'Group ID',
      adIDDesc: 'This is the group ID.',
      adAccess: 'Group access level',
      adAccessDesc: 'Set the access level.',
      adDepAccess: 'Department Access',
      adDepAccessDesc: 'Set which departments can be accessed by this group.',
      adDepAll: 'All departments',
      adDepNone: 'No departments created',
      adName: 'Group Name',
      adNameDesc: 'Set an optional name of the group so it\'s easier to find.',
      adNamePlace: 'E.g. "Managers of Service Desk"',
      adDelete: 'Delete Group',
      adDeleteTitle: 'Delete AD Group',
      adDeleteWarn: 'You are about to delete the group:',
      adSave: 'Save AD Groups',
      adNewGroup: 'New Group',
      adIDError: 'Group ID Error',
      adIDBlank: 'The ID cannot be blank',
      adToastTitle: 'AD Group updated',
      adToastBody: 'The AD Group configuration was saved successfully.',
      deletingDefaultDepartmentModal: {
        body: 'This department is selected under the General setting "@:(message.general.incomingChatDepartment)". You must select a different department before it can be deleted.',
        title: 'Deletion Error',
      },
      nonSelectableDefaultDepartment: 'This department is selected under the General setting "@:(message.general.incomingChatDepartment)". It must be selectable by visitors before you can save.',
      noSelectableDepartments: 'At least one department must be selectable.',
    },
    userManagement: {
      dataField: 'Data Field',
      dataFieldDesc: 'Extra data field bound to this user which can be used for integrations as {{agent.externalUri}}.',
      details: 'User Details',
      unnamed: 'Unnamed User',
      filter: 'Search for a user',
      noUser: 'No users found.',
      noUserSearch: 'No matching user found.',
      addNew: 'Add New User',
      addTitle: 'Adding New User',
      nameDesc: 'Enter a username. | This is the user\'s username.',
      password: 'User Password',
      passwordValidation: {
        MinimumLengthValidator: 'Is at least 8 characters.',
        CommonPasswordValidator: 'Is not a common password.',
        NumericPasswordValidator: 'Is not only numbers.',
        UserAttributeSimilarityValidator: 'Is not similar to other user attributes.',
      },
      email: 'User Email',
      emailDesc: 'Enter an email. This is used when resetting password.',
      emailError: 'Field cannot be empty and the email must be valid.',
      type: 'User Type',
      typeDesc: 'Set whether this user is for a chatbot or a human.',
      typeExportColumnName: 'Chatbot Account',
      saveUser: 'Save new user',
      saveToastTitle: 'User added',
      saveToastBody: '{name} was added successfully.',
      editToastTitle: 'User updated',
      editToastBody: '{name} was updated successfully.',
      deleteToastTitle: 'User deleted',
      deleteToastBody: '{userName} was deleted successfully.',
      selectUser: 'Please select a user',
      editingUser: 'Editing {name}',
      displayName: 'User Display Name',
      displayDesc: 'Set the user display name.',
      displayError: 'This cannot be blank',
      userID: 'User ID',
      userIDDesc: 'This is the user\'s ID.',
      userLastActive: '@:vocabulary.last_active',
      userLastActiveDesc: 'Most recent user activity.',
      isAdmin: 'Is System Admin',
      isAdminDesc: 'System Admins have access to all tenants and departments.',
      accessType: 'Access Type',
      accessTypeDesc: 'Select whether access is set per tenant or department.',
      accessDep: 'Per Department',
      accessTenant: 'Per Tenant',
      accessLevel: 'Access Level | Access Levels',
      accessAD: 'User Access is controlled via AD Groups. You are able to view the user\'s '
        + 'access levels but changes will not be saved. If you wish to change the access levels '
        + 'then you must add the user to a different AD Group.',
      filterDepName: 'Filter departments by name',
      filterDepTenant: 'Filter departments by tenant',
      noAccessTypes: 'No {type} found',
      depAmount: 'Number of Departments',
      accessControl: 'Controlled at Tenant level',
      deleteUser: 'Delete User',
      deleteUserWarn: 'You are about to delete {name}',
      passwordValidationError: '{error}',
      export: {
        exportUsersBtn: 'Export Users',
        modalTitle: 'Export List Of Users',
        fileType: 'File Type',
        fileTypeDesc: 'Select which file type the results should be exported to.',
        okButton: 'Export',
      },
      userTokens: {
        list: 'API Tokens',
        listDesc: 'Create and revoke tokens. You can create up to 2 tokens per user.',
        add: 'Create New API Token',
        addBtn: 'Create Token',
        delete: 'Revoke API Token',
        deleteModalBody: 'Are you sure that you want to revoke the token? It can no longer be used. This cannot be undone.',
        expiry: 'Expiry Date',
        expiryDesc: 'Choose when the token should expire. The token will stop working once the date is reached.',
        maxTokensModalBody: 'You cannot create more than 2 tokens per user. You must revoke at least 1 token to continue.',
        view: 'Token',
        viewDesc: 'This token can be used until the expiry date is reached.',
        viewCloseWarning: 'Did you copy the token? When the window is closed, the token can no longer be viewed or copied.',
      },
    },
    saveChanges: 'Save Changes',
    saveSuccess: 'Changes were saved!',
    externalURI: {
      sendSessionLink: 'Send Remote Support Link',
      missingLinkModal: {
        title: 'Missing Remote Support Link',
        body: 'SupChat was unable to obtain your remote session URL from BeyondTrust.'
          + '\nPlease log out of SupChat.\nThen, make sure that you are logged into the '
          + 'BeyondTrust platform and that you are online, before logging into SupChat.'
          + '\nIf this message persists, please contact your manager.',
      },
    },
    translateTo: 'Translate to {language}',
    translatedFrom: 'Translated from {language}',
    translationPreviewGoBack: 'Return to original message',
    overtakeChat: 'Take Over Chat',
  },
  months: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  daysShort: [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
  ],
  days: [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ],
  // for simple words that repeat in several places
  vocabulary: {
    action: 'Action | Actions',
    administration: 'Administration',
    after: 'After',
    article: 'Article | Articles',
    ascending: 'Ascending',
    avatar: 'Avatar',
    average: 'Average',
    integration: 'Integration | Integrations',
    add: 'Add',
    admin: 'Admin | Admins',
    agentSingle: 'Agent',
    agentMultiple: 'Agents',
    agent: '@:vocabulary.agentSingle | @:vocabulary.agentMultiple',
    all: 'All',
    angry: 'Angry',
    anonymization: 'Anonymization',
    away: 'Away',
    back: 'Back',
    background: 'Background',
    bad: 'Bad',
    bar: 'Bar',
    boxSingle: 'Box',
    boxMultiple: 'Boxes',
    box: '@:vocabulary.boxSingle | @:vocabulary.boxMultiple',
    cancel: 'Cancel',
    change: 'Change',
    chat: 'Chat | Chats',
    chatbot: 'Chatbot',
    chat_id: 'Chat ID',
    close: 'Close',
    closed: 'Closed',
    columnSingle: 'Column',
    columnMultiple: 'Columns',
    column: '@:vocabulary.columnSingle | @:vocabulary.columnMultiple',
    comment: 'Comment',
    confidence: 'Confidence',
    confirm: 'Confirm',
    content: 'Content',
    contrast: 'Contrast',
    conversation: 'Conversation | Conversations',
    copied: 'Copied',
    copy: 'Copy',
    created: 'Created',
    custom: 'Custom',
    daily: 'Daily',
    danish: 'Danish',
    date: 'Date | Dates',
    day: 'Day | Days',
    delay: 'Delay',
    delimiter: 'Delimiter',
    delete: 'Delete',
    deleted: 'Deleted',
    departmentSingle: 'Department',
    departmentMultiple: 'Departments',
    department: '@:vocabulary.departmentSingle | @:vocabulary.departmentMultiple',
    descending: 'Descending',
    disabled: 'Disabled',
    display_name: 'Display Name',
    download: 'Download',
    edit: 'Edit',
    email: 'Email',
    empty: 'Empty',
    enabled: 'Enabled',
    ended: 'Ended',
    endpoint: 'Endpoint | Endpoints',
    english: 'English',
    error: 'Error',
    every: 'Every',
    exclude: 'Exclude',
    expand: 'Expand',
    expires: 'Expires',
    false: 'False',
    first: 'First',
    format: 'Format',
    friday: 'Friday',
    general: 'General',
    good: 'Good',
    hour: 'Hour | Hours',
    everyMinute: 'Minute',
    happy: 'Happy',
    hourly: 'Hourly',
    human: 'Human',
    id: 'ID',
    include: 'Include',
    invalid: 'Invalid',
    invert: 'Invert',
    invisible: 'Offline',
    kpi: 'KPI',
    label: 'Label',
    language: 'Language | Languages',
    last_active: 'Last Activity',
    legend: 'Legend',
    line: 'Line',
    link: 'Link',
    loading: 'Loading',
    manager: 'Manager',
    meta: 'Meta',
    method: 'Method',
    metric: 'Metric | Metrics',
    minute: 'Minute | Minutes',
    minuteShort: 'Min.',
    modify: 'Modify',
    monday: 'Monday',
    month: 'Month | Months',
    monthly: 'Monthly',
    name: 'Name',
    negative: 'Negative',
    neutral: 'Neutral',
    no: 'No',
    none: 'None',
    note: 'Note',
    occurences: 'Occurences',
    offline: 'Offline',
    online: 'Online',
    ok: 'OK',
    option: 'Option | Options',
    parameter: 'Parameter | Parameters',
    password: 'Password',
    percent: '%',
    predominantly: 'Predominantly',
    positive: 'Positive',
    prediction: 'Prediction',
    preview: 'Preview',
    ratingNone: 'No Rating',
    rating: 'Rating',
    rearrange: 'Rearrange',
    regEx: 'RegEx | Regular Expression',
    repeat: 'Repeat',
    replacement: 'Replacement',
    reset: 'Reset',
    resolution: 'Interval',
    response: 'Response | Responses',
    save: 'Save',
    saving: 'Saving',
    saturday: 'Saturday',
    scatter: 'Scatter',
    search: 'Search',
    second: 'Second | Seconds',
    secondCount: 'Second',
    select: 'Select',
    selected: 'Selected',
    send: 'Send',
    shrink: 'Shrink',
    sort: 'Sort',
    star: '{number} Star | {number} Stars',
    status: 'Status',
    subject: 'Subject',
    submit: 'Submit',
    suggested: 'Suggested',
    sunday: 'Sunday',
    sso: 'Single Sign On',
    tag: 'Tag | Tags',
    taken: 'Taken',
    talking: 'Talking',
    target: 'Target',
    tenantSingle: 'Tenant',
    tenantMultiple: 'Tenants',
    tenant: '@:vocabulary.tenantSingle | @:vocabulary.tenantMultiple',
    text: 'Text',
    thursday: 'Thursday',
    timeout: 'Timeout',
    timeRange: 'Time Range',
    timestamp: 'Timestamp',
    timezone: 'Time Zone',
    today: 'Today',
    training: 'Training',
    transfer: 'Transfer',
    true: 'True',
    tuesday: 'Tuesday',
    type: 'Type',
    unchanged: 'Unchanged',
    unit: '@:vocabulary.unitSingle | @:vocabulary.unitMultiple',
    unitSingle: 'Unit',
    unitMultiple: 'Units',
    unknown: 'Unknown',
    untranslated: 'No Translation',
    update: 'Update',
    user__username: '@:vocabulary.username',
    userSingle: 'User',
    userMultiple: 'Users',
    user: '@:vocabulary.userSingle | @:vocabulary.userMultiple',
    username: 'Username',
    value: 'Value',
    view: 'View',
    visible: 'Visible',
    visitorSingle: 'Visitor',
    visitorMultiple: 'Visitors',
    visitor: '@:vocabulary.visitorSingle | @:vocabulary.visitorMultiple',
    waiting: 'Waiting',
    wednesday: 'Wednesday',
    week: 'Week | Weeks',
    weekly: 'Weekly',
    word: 'Word | Words',
    year: 'Year | Years',
    yes: 'Yes',
    yesterday: 'Yesterday',
    widgetConfigs: 'Chat Widget',
    x: 'X',
    y: 'Y',
  },
  errors: {
    blobError: 'The changes were invalid and were not saved. Previous settings will be restored. Please try again later.',
    chatlogError: 'The logs for this chat were not found.',
    failedToCreateUser: 'Failed to create a new user.',
    failedToFetchMyChats: 'Could not fetch your chats after 10 retries. Refresh the page to try again.',
    unknownError: 'An unknown error occured.',
    failedtoFetchAnonymization: 'Failed to fetch anonymization settings.',
    widgetConfigFailedToCreate: 'Failed to create new widget config.',
    widgetConfigFailedToEdit: 'Failed to save widget config changes.',
    widgetConfigFailedToDelete: 'Failed to delete the widget config.',
    noDefaultDepartment: 'There is no default department set for {tenant}. '
      + 'You must set one under the tenant\'s "@:(vocabulary.general)" settings',
    translationFailed: 'The message could not be translated.',
  },
  validation: {
    arrayMinLength: 'At least {minLength} must be selected.',
    maxLength: 'You cannot enter more than {maxLength} characters.',
    required: 'This field is required and cannot be blank.',
  },
};
