var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isIE11)?_c('div',{staticClass:"d-flex w-100 h-100 justify-content-center align-items-center"},[_vm._m(0)]):_c('app-template',_vm._b({scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('locale-select')]},proxy:true},(_vm.loggedIn)?{key:"before-nav",fn:function(){return [_c('div',{staticClass:"px-3 mt-3 d-flex justify-content-center",staticStyle:{"z-index":"500"}},[_c('LoginStatus')],1)]},proxy:true}:null,(_vm.loggedIn)?{key:"after-nav",fn:function(){return [_c('AgentStatusList')]},proxy:true}:null,{key:"page-content",fn:function(){return [_c('transition',{attrs:{"mode":"out-in","name":"fade"}},[_c('router-view')],1),(_vm.loadChatComp)?_c('chat-entry',{ref:"chat",attrs:{"slot":"footer"},slot:"footer"}):_vm._e(),(_vm.loadChatComp)?_c('chat-notification',{attrs:{"slot":"notification"},slot:"notification"}):_vm._e(),_c('error-display'),_c('NotificationPopover')]},proxy:true}],null,true)},'app-template',{
      pages: _vm.pages,
      showSidebar: _vm.loggedIn,
      userDetails: _vm.userDetails,
      userOptionPages: _vm.userOptionPages,
      toolDetails: {
        name: 'SupChat',
        logo: require('./assets/supwiz-logo-flat.svg'),
        env: _vm.env,
        version: _vm.supchatVersion.version,
      },
      loggedIn: _vm.loggedIn,
      localizationObj: _vm.localizationObj,
      noMainPage: true,
    },false)),_c('NotificationsGuideModal',{ref:"GuideModal"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"text-center px-3"},[_vm._v(" This browser is not supported. Please use a supported browser such as Edge, Firefox, or Chrome. "),_c('br'),_c('br'),_vm._v(" Denne browser er ikke understøttet. Brug venligst en understøttet browser fx Edge, Firefox eller Chrome. ")])
}]

export { render, staticRenderFns }