export function secondsToHHMMSS(duration) {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "01:01" or "04:03:59" or "123:03:59"
  let ret = '';
  if (hrs) ret += `${hrs < 10 ? `0${hrs}` : hrs}:`;
  if (mins < 10) ret += '0';
  ret += `${mins}:${secs < 10 ? '0' : ''}`;
  ret += `${secs}`;
  return ret;
}

export const metricValueHandlers = [
  {
    metrics: ['chat_count', 'ended_chat_count'],
    higherIsBetter: true,
    unit: 'vocabulary.chat',
    result: (value) => {
      if (typeof value === 'number') return value;
      return value.reduce((a, b) => a + b);
    },
  },
  {
    metrics: ['concurrent_chats'],
    higherIsBetter: true,
    unit: 'vocabulary.chat',
    result: (value) => {
      if (typeof value === 'number') return value;
      const noEmpties = value.filter((val) => val > 0);
      if (!noEmpties.length) return 0;
      return noEmpties.reduce((a, b) => a + b, 0) / noEmpties.length;
    },
  },
  {
    metrics: ['chat_ratings'],
    higherIsBetter: true,
    unit: 'vocabulary.star',
    result: (value) => {
      if (!value) return 0;
      let tempValue = value;
      if (typeof value === 'number') return value;
      if (!Array.isArray(value)) tempValue = [value];
      // create list of all ratings given
      const possibleRating = new Set(...tempValue.map((set) => Object.keys(set)
        .filter((rating) => rating !== 'none')));
      const ratingOverview = {};
      [...possibleRating].forEach((rating) => {
        const ratingCount = tempValue
          .map((set) => (set[rating] ? set[rating] : 0))
          .reduce((a, b) => a + b, 0);
        ratingOverview[rating] = ratingCount;
      });
      const totalRatedChats = Object.values(ratingOverview)
        .reduce((a, b) => a + b, 0);
      const ratingsMultiplied = Object.entries(ratingOverview)
        .map(([rating, count]) => rating * count)
        .reduce((a, b) => a + b, 0);
      return totalRatedChats ? ratingsMultiplied / totalRatedChats : 0;
    },
  },
  {
    metrics: ['chat_translated', 'metadata'],
    higherIsBetter: true,
    unit: 'vocabulary.chat',
    // we can't calculate any average or total here so we just return the value
    result: (value) => value,
  },
  {
    metrics: ['chat_abandonment'],
    higherIsBetter: true,
    unit: 'vocabulary.percent',
    result: (value) => {
      // value is array of objects {x = not abandoned chats, y = abandoned chats}
      const noEmpties = value.filter(({
        chats_with_agent_message_after_transfer: x,
        chats_ended_while_waiting: y,
      }) => (x + y) > 0);
      if (!noEmpties.length) return 0;
      return noEmpties.map(({
        chats_with_agent_message_after_transfer: x,
        chats_ended_while_waiting: y,
      }) => {
        // no abandoned chats
        if (y === 0) return 100;
        // only abandoned chats
        if (x === 0) return 0;

        const total = x + y;
        return (x / total) * 100;
      });
    },
  },
  {
    metrics: ['chat_sentiments'],
    higherIsBetter: true,
    unit: 'vocabulary.percent',
    // recalculate float to percent
    result: (value) => {
      if (typeof value === 'object') {
        const keys = ['positive', 'negative', 'neutral'];
        const result = {};
        keys.forEach((mood) => {
          const moodValue = value[mood];
          if (moodValue) result[mood] = moodValue * 100;
          else result[mood] = 0;
        });
        return result;
      }
      return value * 100;
    },
  },
  {
    metrics: [
      'average_handling_time',
      'average_queue_time',
      'average_visitor_wait_for_reply_time',
      'average_agent_wait_for_reply_time',
    ],
    higherIsBetter: false,
    unit: 'vocabulary.minute',
    formatter: (minutes) => secondsToHHMMSS(minutes * 60),
    result: (value) => {
      if (typeof value === 'object') {
        const noEmpties = value.filter((val) => val > 0);
        if (!noEmpties.length) return 0;
        return (noEmpties.reduce((a, b) => a + b, 0) / 60) / noEmpties.length;
      }
      return value / 60;
    },
  },
  {
    metrics: ['average_handling_time_kpi', 'average_queue_time_kpi'],
    higherIsBetter: true,
    unit: 'vocabulary.percent',
    result: (value) => {
      if (typeof value === 'object') {
        const noEmpties = value.filter((val) => val > 0);
        if (!noEmpties.length) return 0;
        return (noEmpties.reduce((a, b) => a + b, 0) / noEmpties.length) * 100;
      }
      return value * 100;
    },
  },
];

export const reportTemplates = {
  tenant: {
    // remember to add title and description in translations file
    reportA: [{
      metric: 'chat_count', layout: 'value', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'chat_count', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'average_queue_time', layout: 'value', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'average_queue_time', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'average_handling_time', layout: 'value', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'average_handling_time', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'metadata(device/os)', layout: 'table', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'metadata(device/browser)', layout: 'table', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'metadata(site_location/title)', layout: 'table', ascending: false, groupBy: 'tenant',
    }],
    reportB: [{
      metric: 'chat_count', layout: 'value', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'chat_count', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'chat_ratings', layout: 'graph+table', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'chat_ratings', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'average_queue_time', layout: 'value', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'average_queue_time', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'average_handling_time', layout: 'value', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'average_handling_time', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'chat_translated', layout: 'table', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'metadata(device/os)', layout: 'table', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'metadata(device/browser)', layout: 'table', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'metadata(site_location/title)', layout: 'table', ascending: false, groupBy: 'tenant',
    }],
    reportC: [{
      metric: 'chat_count', layout: 'value', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'chat_count', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'chat_ratings', layout: 'graph+table', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'chat_ratings', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'average_queue_time', layout: 'value', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'average_queue_time', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'average_handling_time', layout: 'value', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'average_handling_time', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'chat_sentiments', layout: 'graph+table', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'metadata(device/os)', layout: 'table', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'metadata(device/browser)', layout: 'table', ascending: false, groupBy: 'tenant',
    }, {
      metric: 'metadata(site_location/title)', layout: 'table', ascending: false, groupBy: 'tenant',
    }],
  },
  department: {
    reportA: [{
      metric: 'chat_count', layout: 'value', ascending: false, groupBy: 'department',
    }, {
      metric: 'chat_count', layout: 'table', ascending: false, groupBy: 'agent',
    }, {
      metric: 'average_queue_time', layout: 'value', ascending: false, groupBy: 'department',
    }, {
      metric: 'average_visitor_wait_for_reply_time', layout: 'table', ascending: false, groupBy: 'agent',
    }, {
      metric: 'average_handling_time', layout: 'value', ascending: false, groupBy: 'department',
    }, {
      metric: 'average_handling_time', layout: 'table', ascending: false, groupBy: 'agent',
    }, {
      metric: 'metadata(device/os)', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'metadata(device/browser)', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'metadata(site_location/title)', layout: 'table', ascending: false, groupBy: 'department',
    }],
    reportB: [{
      metric: 'chat_count', layout: 'value', ascending: false, groupBy: 'department',
    }, {
      metric: 'chat_count', layout: 'table', ascending: false, groupBy: 'agent',
    }, {
      metric: 'chat_ratings', layout: 'graph+table', ascending: false, groupBy: 'department',
    }, {
      metric: 'chat_ratings', layout: 'table', ascending: false, groupBy: 'agent',
    }, {
      metric: 'average_queue_time', layout: 'value', ascending: false, groupBy: 'department',
    }, {
      metric: 'average_visitor_wait_for_reply_time', layout: 'table', ascending: false, groupBy: 'agent',
    }, {
      metric: 'average_handling_time', layout: 'value', ascending: false, groupBy: 'department',
    }, {
      metric: 'average_handling_time', layout: 'table', ascending: false, groupBy: 'agent',
    }, {
      metric: 'chat_translated', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'metadata(device/os)', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'metadata(device/browser)', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'metadata(site_location/title)', layout: 'table', ascending: false, groupBy: 'department',
    }],
    reportC: [{
      metric: 'chat_count', layout: 'value', ascending: false, groupBy: 'department',
    }, {
      metric: 'chat_count', layout: 'table', ascending: false, groupBy: 'agent',
    }, {
      metric: 'chat_ratings', layout: 'graph+table', ascending: false, groupBy: 'department',
    }, {
      metric: 'chat_ratings', layout: 'table', ascending: false, groupBy: 'agent',
    }, {
      metric: 'average_queue_time', layout: 'value', ascending: false, groupBy: 'department',
    }, {
      metric: 'average_visitor_wait_for_reply_time', layout: 'table', ascending: false, groupBy: 'agent',
    }, {
      metric: 'average_handling_time', layout: 'value', ascending: false, groupBy: 'department',
    }, {
      metric: 'average_handling_time', layout: 'table', ascending: false, groupBy: 'agent',
    }, {
      metric: 'chat_sentiments', layout: 'graph+table', ascending: false, groupBy: 'department',
    }, {
      metric: 'metadata(device/os)', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'metadata(device/browser)', layout: 'table', ascending: false, groupBy: 'department',
    }, {
      metric: 'metadata(site_location/title)', layout: 'table', ascending: false, groupBy: 'department',
    }],
  },
};

export const metrics = {
  CHAT_COUNT: 'chat_count',
  ENDED_CHAT_COUNT: 'ended_chat_count',
  AVERAGE_HANDLING_TIME: 'average_handling_time',
  AVERAGE_QUEUE_TIME: 'average_queue_time',
  AVERAGE_HANDLING_TIME_KPI: 'average_handling_time_kpi',
  AVERAGE_QUEUE_TIME_KPI: 'average_queue_time_kpi',
  AVERAGE_VISITOR_WAIT_FOR_REPLY_TIME: 'average_visitor_wait_for_reply_time',
  AVERAGE_AGENT_WAIT_FOR_REPLY_TIME: 'average_agent_wait_for_reply_time',
  CHAT_RATINGS: 'chat_ratings',
  CONCURRENT_CHATS: 'concurrent_chats',
  CHAT_TRANSLATED: 'chat_translated',
  CHAT_SENTIMENTS: 'chat_sentiments',
  CHAT_ABANDONMENT: 'chat_abandonment',
};

export const availableMetrics = [
  metrics.CHAT_COUNT,
  metrics.ENDED_CHAT_COUNT,
  metrics.AVERAGE_HANDLING_TIME,
  metrics.AVERAGE_QUEUE_TIME,
  metrics.AVERAGE_HANDLING_TIME_KPI,
  metrics.AVERAGE_QUEUE_TIME_KPI,
  metrics.AVERAGE_VISITOR_WAIT_FOR_REPLY_TIME,
  metrics.AVERAGE_AGENT_WAIT_FOR_REPLY_TIME,
  metrics.CHAT_RATINGS,
  metrics.CONCURRENT_CHATS,
  metrics.CHAT_TRANSLATED,
  metrics.CHAT_SENTIMENTS,
  metrics.CHAT_ABANDONMENT,
  // 'chat_ids'
];

export const analyticsColors = [
  'rgb(42, 128, 185)',
  'rgb(243, 156, 17)',
  'rgb(39, 174, 97)',
  'rgb(193, 57, 43)',
  'rgb(45, 62, 80)',
  'rgb(143, 68, 173)',
  'rgb(53, 152, 219)',
  'rgb(210, 84, 0)',
  'rgb(126, 140, 141)',
  'rgb(241, 196, 15)',
  'rgb(22, 160, 134)',
  'rgb(52, 73, 94)',
  'rgb(232, 76, 61)',
  'rgb(45, 204, 112)',
  'rgb(155, 88, 181)',
  'rgb(149, 165, 165)',
  'rgb(231, 126, 35)',
  'rgb(27, 188, 155)',
  'rgb(190, 195, 199)',
];

export function mapRatingColor(rating, maxRating, saturation = 70, lightness = 50) {
  const ratingAsFloat = (rating - 1) / (maxRating - 1);
  const hue = (ratingAsFloat * 120).toString(10);
  return `hsl(${hue},${saturation}%,${lightness}%)`;
}

export const browserDetails = {
  ie: {
    name: 'Internet Explorer',
    // icon: 'internet-explorer',
  },
  firefox: {
    name: 'Firefox',
    // icon: 'firefox-browser',
  },
  fxios: {
    name: 'Firefox (iOS)',
    // icon: 'firefox-browser',
  },
  'edge-chromium': {
    name: 'Edge (Chromium)',
    // icon: 'edge',
  },
  chrome: {
    name: 'Chrome',
    // icon: 'chrome',
  },
  chrios: {
    name: 'Chrome (iOS)',
    // icon: 'chrome',
  },
  edge: {
    name: 'Edge',
    // icon: 'edge-legacy',
  },
  'edge-ios': {
    name: 'Edge (iOS)',
    // icon: 'edge-legacy',
  },
  facebook: {
    name: 'Facebook',
    // icon: 'opera',
  },
  'ios-webview': {
    name: 'iOS (Webview)',
    // icon: 'opera',
  },
  safari: {
    name: 'Safari',
    // icon: 'opera',
  },
  samsung: {
    name: 'Samsung Internet',
    // icon: 'opera',
  },
  opera: {
    name: 'Opera',
    // icon: 'opera',
  },
  yandexbrowser: {
    name: 'Yandex Browser',
    // icon: 'opera',
  },
};

export function compressBrowserData({
  currentValues = {},
  prevValues = {},
}) {
  const compressedData = {};
  function compress(valueObj, prev = false) {
    const valueKey = prev ? 'prev' : 'value';
    Object.entries(valueObj).forEach(([browserKey, browserValue]) => {
      // separate browser name and version, filter out blanks, and trim again.
      const [browser, version] = browserKey.split(/([\s+])(.*)/).filter((x) => x.trim()).map((x) => x.trim());
      // initiate browser object if it doesnt exist
      if (!(browser in compressedData)) {
        compressedData[browser] = {
          versions: {},
          id: browser,
          name: browserDetails[browser]?.name,
          value: 0,
          prev: 0,
        };
      }
      // initiate version to versions.
      if (!(version in compressedData[browser].versions)) {
        compressedData[browser].versions[version] = {
          value: 0,
          prev: 0,
        };
      }
      // add version value or prev
      compressedData[browser].versions[version][valueKey] += browserValue;

      // add to browser total value or prev
      compressedData[browser][valueKey] += browserValue;
    });
  }
  if (Object.keys(currentValues).length) compress(currentValues);
  if (Object.keys(prevValues).length) compress(prevValues, true);
  return compressedData;
}

export const metadataMetricRegex = /metadata\((.*?)\)/;
