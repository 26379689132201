<template>
  <b-dropdown
    v-b-tooltip="$options.tooltipOptions"
    :text="currentLanguageCode"
    :title="$t('message.insightsPage.chooseLanguage')"
    toggle-class="font-weight-bold text-white language-selector border-0 shadow-none"
    variant="muted"
    no-caret
  >
    <b-dropdown-header>{{ $t('message.insightsPage.chooseLanguage') }}</b-dropdown-header>
    <b-dropdown-item-button
      v-for="{ value, text } in languageOptions"
      :key="value"
      href="#"
      :disabled="$i18n.locale === value"
      @click="localeChangeHandler(value)"
    >
      {{ text }}
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
import { tooltipOptions } from '@/utils/constants';
import { loadLanguageAsync } from '@/localization';

export default {
  name: 'LocaleSelect',
  tooltipOptions: {
    ...tooltipOptions,
    placement: 'bottom',
  },
  computed: {
    currentLanguageCode() {
      return this.$i18n.locale.toUpperCase();
    },
    languageOptions() {
      return [
        {
          value: 'da',
          text: 'Dansk',
        },
        {
          value: 'en',
          text: 'English',
        },
      ];
    },
  },
  created() {
    const language = localStorage.getItem('language');
    if (!language) {
      const browserLanguage = navigator.language;
      for (const { value } of this.languageOptions) {
        if (browserLanguage.includes(value)) {
          this.localeChangeHandler(value);
          return;
        }
      }
      // default to english
      this.localeChangeHandler('en');
    } else {
      this.localeChangeHandler(language);
    }
  },
  methods: {
    localeChangeHandler(locale) {
      loadLanguageAsync(locale);
    },
  },
};
</script>

<style scoped>
  :deep(.language-selector) {
    background: rgba(0,0,0,0.1)!important;
  }
  :deep(.language-selector:hover),
  :deep(.language-selector:focus) {
    background: rgba(0,0,0,0.25)!important;
  }
</style>
