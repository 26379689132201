import { render, staticRenderFns } from "./MultiSelect.vue?vue&type=template&id=3bef5502&scoped=true"
import script from "./MultiSelect.vue?vue&type=script&lang=js"
export * from "./MultiSelect.vue?vue&type=script&lang=js"
import style1 from "./MultiSelect.vue?vue&type=style&index=1&id=3bef5502&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bef5502",
  null
  
)

export default component.exports