<template>
  <b-card
    v-bind="$attrs"
    class="r-50 mb-3 shadow-sm"
    body-class="p-3"
  >
    <slot />
    <template
      v-if="$slots.footer"
      #footer
    >
      <slot name="footer" />
    </template>
  </b-card>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>
